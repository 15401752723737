import ApiService from "./api.service"
const main = "interview-candidate"

export default {
    create(data) {
        return ApiService.post(`${main}/create`, data)
    },
    listAll({ search, params, isArchive }) {
        return ApiService.post(`interview-document/list-all?search=${search}&isArchive=${isArchive}`, params)
    },
    getCandidates(documentId) {
        return ApiService.get(`${main}/list/${documentId}`)
    },
    getAssessors(documentId) {
        return ApiService.get(`interview-assessors/list/${documentId}`)
    },
    listAssessors({ search, params }) {
        return ApiService.post(`user/list-assessors?search=${search || ''}`, params)
    },
    createAssessors(data) {
        return ApiService.post(`interview-assessors/create`, data)
    },
    deleteAssessor(id) {
        return ApiService.delete(`interview-assessors/delete/${id}`)
    },
    getSheet(documentId) {
        return ApiService.get(`interview-candidate/candidate-by-documentId/${documentId}`)
    },
    marking(data) {
        return ApiService.post(`interview-candidate/candidate-marking`, data)
    },
    close(id) {
        return ApiService.get(`interview-assessors/close/${id}`)
    },
    finished(id) {
        return ApiService.get(`interview-document/finished/${id}`)
    },
    start(id) {
        return ApiService.get(`interview-document/meeting-process/${id}`)
    },
    // question generate
    getQuestionType(licenseId) {
        return ApiService.get(`question-type/list-by-license-id/${licenseId}`)
    },
    getQuestionTypeCount({ licenceId, candidateId }) {
        return ApiService.get(`question-type-count/list-by-license-id?licenceId=${licenceId}&candidateId=${candidateId}`)
    },
    randomQuestion(data) {
        return ApiService.post(`document-question/random`, data)
    },
    getQuestionByCandidate(candidateId) {
        return ApiService.get(`document-question/list-by-candidateId/${candidateId}`)
    },
    getQuestionWithAnswer(candidateId) {
        return ApiService.get(`document-question/list-with-answer-byCandidateId/${candidateId}`)
    },
    // Protocol
    protocolCreate(data) {
        return ApiService.post(`announcement-inside-protocol/create`, data)
    },
    protocolList({ params }) {
        return ApiService.post(`announcement-inside-protocol/list-by-own`, params)
    },
    protocolById(id) {
        return ApiService.get(`announcement-inside-protocol/get-by-id/${id}`)
    },
    editProtocol(data) {
        return ApiService.post(`/announcement-inside-protocol/edit`, data)
    },
    cancelProtocol(data) {
        return ApiService.post(`announcement-inside-protocol/cancel`, data)
    },
    whoCanceledBy(protocolId) {
        return ApiService.get(`/announcement-inside-protocol/who-cancelled/${protocolId}`)
    },
    makeSignedProtocol(data) {
        return ApiService.post(`announcement-inside-protocol/make-signed`, data)
    },
    savePdfFile({ protocolId, base64 }) {
        return ApiService.post(`announcement-inside-protocol/savePdfFile/${protocolId}`, {
            base64: `data:application/pdf;base64,${base64}`
        })
    },
    viewPdf(body) {
        return ApiService.post(`/convert/view-pdf`, { body })
    }
}