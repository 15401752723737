<template>
    <div>
        <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="
                intro-y
                col-span-12
                flex flex-wrap
                sm:flex-nowrap
                items-center
                w-full
                mt-2
                gap-4
              ">
                <div class="w-full mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex items-center">
                    <div class="w-56 relative text-gray-700 dark:text-gray-300">
                        <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13"
                            placeholder="Qidiruv" v-model="search" />
                        <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                    </div>
                    <div v-if="roles.includes(role?.code)" class="flex-grow mx-4">
                        <c-select-naive class="w-full" :label="$localeKey('name')" :options="regions"
                            v-model:value="region" />
                    </div>
                    <div class="
                    hidden
                    flex-grow
                    text-center
                    md:block
                    mx-auto
                    text-gray-600
                  ">
                        {{ total }} dan {{ list.length }} ta ko'rsatilmoqda
                    </div>
                    <select v-model="limit" class="w-20 form-select box ml-4">
                        <option v-for="item in limitOpt" :key="item" :value="item">
                            {{ item }}
                        </option>
                    </select>
                </div>

                <button :disabled="!selectedItems.length" :class="{ 'opacity-40': !selectedItems.length }"
                    @click="changeDocStatusModal = true" class="btn btn-primary shadow-md mr-2 whitespace-nowrap">
                    {{ $t("interview.allowResubmission") }}
                </button>
            </div>
            <!-- BEGIN: Data List -->
            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <app-loading :show="loading">
                    <table class="table table-report -mt-2">
                        <thead>
                            <tr>
                                <th class="w-16">
                                    <input class="form-check-input" type="checkbox"
                                        :checked="$_.isEqual(selectedItems, list)" @change="
                                !$_.isEqual(selectedItems, list)
                                    ? (selectedItems = [...list])
                                    : (selectedItems = [])
                                " />
                                </th>
                                <th class="w-4 text-center">#</th>
                                <th class="whitespace-nowrap">
                                    {{ $t("field.fio") }}
                                </th>
                                <th class="text-center">{{ $t("field.sendDate") }}</th>
                                <th class="text-center">
                                    {{ $t("field.address") }}
                                </th>
                                <th class="text-center">
                                    {{ $t("lisenceType") }}
                                </th>
                                <th class="text-center whitespace-nowrap">
                                    {{ $t("field.phone") }}
                                </th>
                                <th class="whitespace-nowrap text-center">
                                    {{ $t("status.title") }}
                                </th>
                                <th class="text-center whitespace-nowrap">
                                    {{ $t("actions.title") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="list.length">
                            <tr v-for="(item, index) in list" :key="index" class="intro-x">
                                <td class="w-16">
                                    <input :value="item" v-model="selectedItems" class="form-check-input"
                                        type="checkbox" />
                                </td>
                                <td class="w-4 text-center">
                                    {{ $paginate(index, page, limit) }}
                                </td>
                                <td class="font-semibold cursor-pointer" @click="
                                selectedItem = item;
                            getInfoCandidate(item);
                            ">
                                    {{ item.candidateFullName }}
                                </td>
                                <td class="text-center">{{ item.date }}</td>
                                <td class="text-center">{{ item.candidateAddress }}</td>
                                <td class="text-center">
                                    <div v-for="(l, index) in item.licenseTypes" :key="index">
                                        {{ index + 1 }}. {{ l[$localeKey()] }}
                                    </div>
                                </td>
                                <td class="w-40">
                                    {{ item.candidateMobilePhone }}
                                </td>
                                <td>
                                    <div class="flex items-center justify-center" :class="{
                                'text-theme-9': item.status == 'SEND',
                                'text-theme-6': item.status == 'FAILED',
                                'text-theme-3': item.status == 'ACCEPTED',
                            }">
                                        <CheckSquareIcon v-if="item.status == 'SEND'" class="w-4 h-4 mr-2" />
                                        <XSquareIcon v-else-if="item.status == 'FAILED'" class="w-4 h-4 mr-2" />
                                        <CheckSquareIcon v-else class="w-4 h-4 mr-2" />
                                        {{ $t(`status.${item.status}`) }}
                                    </div>
                                </td>
                                <td class="table-report__action w-56">
                                    <div class="flex justify-center items-center">
                                        <a class="flex items-center ml-3 btn btn-primary" href="javascript:;" @click="
                                selectedItem = item;
                            getInfoCandidate(item);
                            ">
                                            <UserIcon class="w-5 h-5" />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <not-found v-else colspan="9"></not-found>
                    </table>
                </app-loading>
            </div>
            <!-- END: Data List -->
            <!-- BEGIN: Pagination -->
            <n-pagination v-model:page="page" :page-count="Math.ceil(total / limit)" />
            <!-- END: Pagination -->
        </div>
        <!-- END: Delete Confirmation Modal -->
        <!-- confirm selected -->
        <n-modal v-model:show="changeDocStatusModal" preset="dialog" :mask-closable="false" class="large-modal">
            <template #header>
                <h1 class="">{{ $t("interview.allowResubmission") }}</h1>
            </template>
            <div v-if="uploadedFile" class="flex justify-between items-center p-2 bg-gray-50">
                <p class="text-sm">{{ uploadedFile?.name }}</p>
                <XSquareIcon class="w-4 h-4 mr-2 cursor-pointer text-theme-6" @click="uploadedFile = null" />
            </div>
            <div v-else class="flex items-center justify-center w-full">
                <label for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">{{ $t(`actions.uploadFile`) }}</p>
                    </div>
                    <input id="dropzone-file" type="file" class="hidden" @change="uploadFile" />
                </label>
            </div>

            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table class="table table-report -mt-2">
                    <thead>
                        <tr>
                            <th class="w-16">
                                <input class="form-check-input" type="checkbox" disabled />
                            </th>
                            <th class="whitespace-nowrap">{{ $t("field.fio") }}</th>
                            <th class="whitespace-nowrap text-center">
                                {{ $t("status.title") }}
                            </th>
                            <th class="text-center whitespace-nowrap">
                                {{ $t("actions.title") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="list.length">
                        <tr v-for="(item, index) in selectedItems" :key="index" class="intro-x">
                            <td class="w-16">
                                <input :value="item" v-model="selectedItems" class="form-check-input" type="checkbox" />
                            </td>
                            <td class="font-semibold">
                                {{ item.candidateFullName }}
                            </td>
                            <td>
                                <div class="flex items-center justify-center" :class="{
                                'text-theme-9': item.status == 'SEND',
                                'text-theme-6': item.status == 'CANCELLED',
                                'text-theme-3': item.status == 'ACCEPTED',
                            }">
                                    <CheckSquareIcon v-if="item.status == 'SEND'" class="w-4 h-4 mr-2" />
                                    <XSquareIcon v-if="item.status == 'CANCELLED'" class="w-4 h-4 mr-2" />
                                    <CheckSquareIcon v-else class="w-4 h-4 mr-2" />
                                    {{ $t(`status.${item.status}`) }}
                                </div>
                            </td>
                            <td class="table-report__action w-56">
                                <div class="flex justify-center items-center">
                                    <a class="flex items-center ml-3" href="javascript:;" @click="
                                sidebarShow = true;
                            selectedItem = item;
                            ">
                                        <EyeIcon class="w-4 h-4 m-1" />
                                        {{ $t("actions.view") }}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <not-found v-else colspan="6"></not-found>
                </table>
            </div>
            <template #action>
                <div class="flex justify-end">
                    <button v-if="selectedItems.length" @click="changeDocStatus" class="btn btn-primary shadow-md">
                        {{ $t("interview.allowResubmission") }}
                    </button>
                </div>
            </template>
        </n-modal>
        <!-- Confirm -->
        <n-modal v-model:show="acceptShow" :mask-closable="false" preset="dialog" :title="$t('actions.confirm')"
            :content="$t('actions.confirm')" :positive-text="$t('actions.accept')" :negative-text="$t('actions.cancel')"
            @positive-click="setAcceptStatus" @negative-click="acceptShow = false" />
        <!-- Candidate info -->
        <n-modal v-model:show="sidebarShow" preset="dialog" class="extra-modal bg-theme-2">
            <app-loading :show="loading" class="">
                <div class="grid grid-cols-12 gap-6">
                    <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
                        <div class="relative flex items-center p-5">
                            <div class="w-24 h-24 image-fit flex-shrink-0">
                                <n-image v-if="candidateInfo?.uploadPath" class="rounded-full wrap-can-image"
                                    :src="`${$baseUrl}/${candidateInfo?.uploadPath}`" />
                                <img v-else class="rounded-full" src="" alt />
                            </div>
                            <div class="ml-4 mr-auto">
                                <div class="font-medium text-base">
                                    {{ candidateInfo.candidateFullName }}
                                </div>
                                <div class="text-theme-1 text-opacity-60">
                                    {{ candidateInfo.candidateMobilePhone }}
                                </div>
                            </div>
                        </div>
                        <div class="box border-t border-gray-200 dark:border-dark-5" style="min-height: 500px">
                            <div class="px-4 pb-3 pt-5 ref-link">
                                <button class="flex items-center px-4 py-2 mt-1 one-line w-full" :class="{
                                'bg-theme-1 text-white font-medium rounded-lg':
                                    showPassportData,
                            }" @click="showPassportData = true">
                                    <div class="flex-1 truncate">
                                        {{ $t("passportData") }}
                                    </div>
                                </button>
                                <button v-for="(file, index) in candidateInfo.file" :key="index"
                                    class="flex items-center px-4 py-2 mt-1 one-line w-full" :class="{
                                'bg-theme-1 text-white font-medium rounded-lg':
                                    file.id == currentFile.id && !showPassportData,
                            }" @click="
                                currentFile = { ...file };
                            showPassportData = false;
                            ">
                                    <div class="flex-1 truncate">
                                        {{ file[$localeKey("docTypeName")] }}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9"
                        v-if="currentFile.fileType && !showPassportData">
                        <div class="mt-4 mb-2 text-theme-1 text-xl">
                            {{ currentFile[$localeKey("docTypeName")] }}
                        </div>
                        <file-view :file="currentFile" />
                    </div>
                    <div v-else class="intro-y col-span-12 lg:col-span-8 xl:col-span-9 mt-8">
                        <div class="
                bg-white
                rounded
                shadow-sm
                w-full
                p-4
                grid grid-cols-2
                gap-4
              ">
                            <ul class="border rounded-md border-opacity-10 border-dark-3">
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.fio") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidateFullName }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.passportSeria") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidatePassportNumber }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">
                                        {{ $t("field.passportGivendate") }}
                                    </div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidatePassportGivenDate }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">
                                        {{ $t("field.passportEnddate") }}
                                    </div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidatePassportEndDate }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.pinfl") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidatePNFL }}
                                    </div>
                                </li>
                            </ul>
                            <ul class="border rounded-md border-opacity-10 border-dark-3">
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.nationality") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidateNationality }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.birthCountry") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidateBirthAddress }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.address") }}</div>
                                    <div class="col-span-2 text-right">
                                        {{ candidateInfo.candidateAddress }}
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.phone") }}</div>
                                    <div class="col-span-2 text-right">
                                        <a class="text-theme-4 text-opacity-75"
                                            :href="`tel:${candidateInfo.candidateMobilePhone}`">
                                            {{ candidateInfo.candidateMobilePhone }}
                                        </a>
                                    </div>
                                </li>
                                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                                    <div class="col-span-1">{{ $t("field.email") }}</div>
                                    <div class="col-span-2 text-right">
                                        <a class="text-theme-4 text-opacity-75"
                                            :href="`tel:${candidateInfo.candidateEmail}`">
                                            {{ candidateInfo.candidateEmail }}
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </app-loading>
        </n-modal>
    </div>
</template>

<script>
import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import CandidateService from "@/services/candidate/Candidate.service";
import RegionsService from "@/services/reference/region.service";
import FailedListService from "@/services/failed-list.service";
import InterviewForm from "../components/InterviewForm.vue";
import { TokenService } from "@/services/storage.service";
import FileView from "@/components/main/file-view.vue";
import { useLoadingBar, useMessage } from "naive-ui";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
    components: {
        FileView,
        InterviewForm,
        CSelectNaive,
    },
    setup() {
        const changeDocStatusModal = ref(false);
        const loadingBar = useLoadingBar();
        const interviewShow = ref(false);
        const message = useMessage();
        const router = useRouter();
        const { t } = useI18n();
        const page = ref(1);
        const form = ref({
            date: Date.now(),
            phone: "",
        });

        let limitOpt = [10, 20, 50, 100];
        let sidebarShow = ref(false);
        let uploadedFile = ref(null);
        let loading = ref(false);
        let regions = ref([]);
        let region = ref("");
        let search = ref("");
        let limit = ref(20);
        let list = ref([]);
        let total = ref(0);
        let role = ref("");

        let roles = ref(["ROLE_ADMIN", "ROLE_MAIN_ADMIN"]);

        function uploadFile(e) {
            console.log(e.target.files[0]);
            uploadedFile.value = e.target.files[0];
        }

        let paramsComp = computed(() => {
            return {
                search: search.value,
                regionId: region.value?.id || "",
                params: {
                    page: page.value - 1,
                    limit: limit.value,
                },
            };
        });
        async function getList() {
            let payload = { ...paramsComp.value };
            payload.regionId = [payload.regionId];
            try {
                loading.value = true;
                let res = await FailedListService.list(payload);
                list.value = res.data.list;
                total.value = res.data.total;
            } catch {
                list.value = [];
                total.value = 0;
            } finally {
                loading.value = false;
            }
        }
        async function getRegions() {
            let res = await RegionsService.getList();
            regions.value = res.data?.[0]?.children;
        }
        let selectedItem = ref({});
        onMounted(() => {
            role.value = TokenService.getUserRoles();
            getList(paramsComp.value);
            getRegions();
        });
        watch(paramsComp, (val) => {
            getList();
        });
        // Select and send
        let selectedItems = ref([]);
        async function createInterview(e) {
            let payload = {
                ...e,
                candidateIds: selectedItems.value.map((el) => el.id),
            };
            try {
                loadingBar.start();
                await FailedListService.create(payload);
                message.success(t("success.saved"));
                router.push("/interview");
            } finally {
                loadingBar.finish();
            }
        }

        // Show candidate files
        let showPassportData = ref(true);
        let candidateInfo = ref({});
        let currentFile = ref({});

        async function getInfoCandidate(item) {
            sidebarShow.value = true;
            showPassportData.value = true;
            try {
                loading.value = true;
                let res = await CandidateService.changeStatusToSeen(item.candidateId);
                candidateInfo.value = res.data[0] || {};
                currentFile.value = candidateInfo.value.file?.[0] || {};
            } finally {
                loading.value = false;
            }
        }

        async function changeDocStatus() {
            const formData = new FormData()

            if (uploadedFile.value) formData.append('file', uploadedFile.value)

            try {
                loadingBar.start();
                await FailedListService.changeDocStatus(selectedItems.value.map((el) => el.id), formData);
                message.success(t("success.saved"));

                selectedItems.value = []
                uploadedFile.value = null
                changeDocStatusModal.value = false;

                getList();
            } finally {
                loadingBar.finish();
            }
        }
        return {
            page,
            form,
            list,
            role,
            total,
            limit,
            roles,
            search,
            region,
            loading,
            regions,
            limitOpt,
            uploadFile,
            sidebarShow,
            currentFile,
            uploadedFile,
            selectedItem,
            interviewShow,
            selectedItems,
            candidateInfo,
            createInterview,
            showPassportData,
            getInfoCandidate,
            changeDocStatus,
            changeDocStatusModal,
        };
    },
});
</script>
<style lang="scss">
.wrap-can-image {
    img {
        object-fit: contain !important;
    }
}
</style>