<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    style="max-width: 700px"
  >
    <n-form-item :label="$t('field.time')" path="date">
      <n-date-picker
        class="w-full"
        v-model:value="formValue.date"
        type="datetime"
        :placeholder="$t('field.time')"
      />
    </n-form-item>

    <n-form-item :label="$t('field.phoneCadr')" path="phone">
      <n-input
        v-maska="'+9989# ### ## ##'"
        type="text"
        v-model:value="formValue.phone"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('field.text')" path="reason">
      <n-input
        type="textarea"
        v-model:value="formValue.reason"
        placeholder=""
        show-count
        rows="4"
      />
    </n-form-item>
    <n-form-item class="flex items-center justify-end mt-4">
      <button @click="$emit('close')" class="btn btn-secondary">
        {{ $t("actions.cancel") }}
      </button>
      <button @click="validate" class="btn btn-primary ml-4">
        {{ $t("actions.save") }}
      </button>
    </n-form-item>
  </n-form>
</template>

<script setup>
import { dmy, hm } from "@/utils/date";
import { ref, watch, onMounted, computed } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
});
let formValue = ref({
  date: null,
  phone: "",
  reason: "",
});
let rules = {
  date: {
    validator(rule, value) {
      let date = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 5 - 1000 * 60
      );
      console.log(new Date(value), date);
      if (new Date(value) < date) {
        message.warning(t("requestDate"));
        return false;
      }
      return true;
    },
    message: "",
    trigger: ["change", "blur"],
  },
  phone: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  reason: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};
let reasonMessage = computed(() => {
  return `Сизнинг малака имтихони топшириш бўйича юборган ҳужжатларингиз кўриб чиқилди. Сиз малака имтихонини топшириш бўйича  ${dmy(
    formValue.value.date
  )} куни соат ${hm(
    formValue.value.date
  )} га --------------------------- манзилга етиб келишингиз сўралади. Қўшимча маълумот https://e-advokat.uz/profile. Маълумот учун телефон ${
    formValue.value.phone
  }`;
});
watch(
  () => formValue.value.date,
  () => {
    formValue.value.reason = reasonMessage.value;
  }
);
watch(
  () => formValue.value.phone,
  () => {
    formValue.value.reason = reasonMessage.value;
  }
);
onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
  }
});

function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      emits("success", {
        ...formValue.value,
        date: dmy(formValue.value.date),
        time: hm(formValue.value.date),
      });
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style>
</style>