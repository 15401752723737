import ApiService from "../api.service";
const main = "interview-members";

export default {
  getList({ contragentId, params }) {
    return ApiService.post(
      `${main}/list?contragentId=${contragentId || ""}`,
      params
    );
  },
  // http://localhost:8282/api/v.1/interview-members/listDto?contragentId=12
  getListDto({ contragentId, params }) {
    return ApiService.post(
      `${main}/listDto?contragentId=${contragentId || ""}`,
      params
    );
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id/${id}`);
  },
  // http://localhost:8282/api/v.1/interview-members/create?contragentId=12&interviewAppraisersId=12&userId=12
  create({ contragentId, interviewAppraisersId, userId }) {
    return ApiService.post(
      `${main}/create?contragentId=${contragentId ||
        ""}&interviewAppraisersId=${interviewAppraisersId ||
        ""}&userId=${userId || ""}`
    );
  },
  update({ contragentId, interviewAppraisersId, membersId, userId }) {
    // http://localhost:8282/api/v.1/interview-members/update?contragentId=12&interviewAppraisersId=12&membersId=12&userId=12
    return ApiService.post(
      `${main}/update?contragentId=${contragentId ||
        ""}&interviewAppraisersId=${interviewAppraisersId ||
        ""}&membersId=${membersId || ""}&userId=${userId || ""}`
    );
  },
  // http://localhost:8282/api/v.1/interview-members/delete?membersId=12
  delete(id) {
    return ApiService.post(`${main}/delete?membersId=${id}`);
  },
};
