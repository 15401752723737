<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    style="max-width: 700px"
  >
    <div class="grid grid-cols-2 gap-x-4">
      <!-- <n-form-item
        v-maska="'+9989 9# ### ## ##'"
        :label="$t('field.phone')"
        path="phone"
      >
        <n-input v-model:value="formValue.phone" placeholder="" />
      </n-form-item> -->
      <n-form-item :label="$t('user')" path="userId">
        <v-select
          v-model="formValue.userId"
          placeholder=""
          :options="usersFiltered"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          :label="'fullName'"
        >
          <template #option="{ firstName, lastName, contragentNameLt }">
            <h3 class="m-0 font-bold">{{ firstName }} {{ lastName }}</h3>
            <p class="m-0 text-gray-600">{{ contragentNameLt }}</p>
          </template>
        </v-select>
      </n-form-item>
      <n-form-item
        v-if="roles.includes(role?.code)"
        :label="$t('field.contragent')"
        path="contragentId"
      >
        <v-select
          v-model="formValue.contragentId"
          placeholder=""
          :options="contragents"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          :label="$localeKey()"
        />
      </n-form-item>

      <n-form-item :label="$t('field.comType')" path="interviewAppraisersId">
        <v-select
          v-model="formValue.interviewAppraisersId"
          placeholder=""
          :options="comissions"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          :label="$localeKey()"
        />
      </n-form-item>

      <n-form-item class="flex items-center justify-end mt-4 col-span-full">
        <button @click="$emit('close')" class="btn btn-secondary">
          {{ $t("actions.cancel") }}
        </button>
        <button @click="validate" class="btn btn-primary ml-4">
          {{ $t("actions.save") }}
        </button>
      </n-form-item>
    </div>
  </n-form>
</template>

<script setup>
import ContragentService from "@/services/reference/contragent.service";
import RoleService from "@/services/reference/role.service";
import StaffService from "@/services/reference/staff-position.service";
import UserService from "@/services/reference/user.service";
import ComissionsService from "@/services/reference/commission-type.service";
import { TokenService } from "@/services/storage.service";

import { ref, watch, onMounted, computed } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
let role = ref("");
let roles = ref(["ROLE_ADMIN", "ROLE_MAIN_ADMIN"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
});
let formValue = ref({
  contragentId: "",
  userId: "",
  interviewAppraisersId: "",
});
let rules = {
  userId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  interviewAppraisersId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};
// Options
let payload = ref({
  page: 0,
  limit: 50,
});
let contragents = ref([]);
// let positions = ref([]);
let users = ref([]);
let comissions = ref([]);
async function getContragent(data) {
  let res = await ContragentService.getList({ params: data });
  contragents.value = res.data.list;
}
async function getUsers(data) {
  let res = await UserService.getList({ params: data });
  users.value = res.data.list;
}

let usersFiltered = computed(() => {
  return users.value.map((item) => {
    return {
      ...item,
      fullName: `${item.firstName} ${item.lastName}`,
    };
  });
});

async function getComissions(data) {
  let res = await ComissionsService.getList({ params: data });
  comissions.value = res.data.list;
}
onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
  }
  getContragent(payload.value);
  // getPosition(payload.value);
  getUsers(payload.value);
  getComissions(payload.value);
  role.value = TokenService.getUserRoles();
});

function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      if (props.mode == "update") {
        delete formValue.value.password;
      }
      emits("success", formValue.value);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style>
</style>