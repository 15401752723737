
import { UserService, AuthenticationError } from '../services/user.service'
import { TokenService } from '../services/storage.service'
import router from '../router'

const state = {
    user: {
        permissions: []
    },
    show401Error: false,
    userName: TokenService.getUserName(),
    isAdmin: TokenService.getIsAdmin(),
    authenticating: false,
    rules: [],
    userId: null,
    token: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    count: 0
};

const getters = {
    loggedIn: (state) => {
        return !!state.token
    },

    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },

    authenticationError: (state) => {
        return state.authenticationError
    },

    authenticating: (state) => {
        return state.authenticating
    },

    userPermissions: (state) => {
        return state.user.permissions
    }
}

const actions = {
    async login({ commit }, userData) {
        commit('loginRequest');
        try {
            const user = await UserService.login(userData);
            await commit('loginSuccess', user);
            let routeHistory = router.history?.current?.query?.redirect;
            router.push(routeHistory && routeHistory != '/login' ? routeHistory : '/');
            return true
        } catch (e) {
            commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
            return false
        }
    },

    async loginByToken({ commit }, userData) {
        commit('loginRequest');
        try {
            const user = await UserService.loginByEsp(userData);
            await commit('loginSuccess', user);
            let routeHistory = router.history.current.query.redirect;
            router.push(routeHistory && routeHistory != '/login' ? routeHistory : '/');
            return true
        } catch (e) {
            commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
            return false
        }
    },

    async logout({ state, commit }) {
        UserService.logout();
        commit('logoutSuccess');
        router.push('/login').catch(() => { })
    }
}


const mutations = {
    show401ErorMessage(state, boolean) {
        state.show401Error = boolean
    },

    loginRequest(state) {
        state.count = 0
        state.authenticating = true;
        state.authenticationError = '';
        state.authenticationErrorCode = 0
    },

    loginSuccess(state, user) {
        let formattedRules = [];
        if (user.permissions.length > 0) {
            formattedRules = user.permissions.map(perm => {
                let formattedObj = {};
                formattedObj.actions = perm.substr(0, perm.indexOf(' '));
                formattedObj.subject = perm.substr(perm.indexOf(' ') + 1);
                return formattedObj;
            })
        }
        state.rules = formattedRules;
        state.user = user;
        state.userId = user.id;
        state.token = user.token;
        state.authenticating = false;
        state.userName = TokenService.getUserName();
    },

    loginError(state, { errorCode, errorMessage }) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },

    logoutSuccess(state) {
        state.token = '';
        state.rules = [];
        state.userId = null;
    }
}

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
