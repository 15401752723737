import { createI18n } from "vue-i18n";
// Localisation language list
import { locale as lt } from "@/core/i18n/uzLatin";
import { locale as uz } from "@/core/i18n/uzCrill";
import { locale as ru } from "@/core/i18n/ru";
import { locale as eng } from "@/core/i18n/eng";
import { langMethods } from "@/core/utils";

let messages = {};
messages = { ...messages, lt, uz, ru, eng };

// get current selected language
const lang = localStorage.getItem("language") || "lt";

// Create VueI18n instance with options
const i18n = createI18n({
  locale: lang, // set locale
  messages, // set locale messages
  missing: (locale, message, messages) => {
    if (locale == "eng" || locale == "ru") return message;
    let splitMes = message.split(".");
    if (splitMes.length == 2) {
      let obj = splitMes[0];
      let key = splitMes[1];
      if (locale == "uz" && lt[obj]?.[key]) {
        return langMethods.toCyrill(lt[obj][key]);
      }
      if (locale == "lt" && uz[obj]?.[key]) {
        return langMethods.toLatin(uz[obj][key]);
      }
    }
    if (locale == "uz" && lt[message]) {
      return langMethods.toCyrill(lt[message]);
    }
    if (locale == "lt" && uz[message]) {
      return langMethods.toLatin(uz[message]);
    }
    return message;
  },
  silentTranslationWarn: true,
});

export default i18n;
