<template>
  <div class="mt-12">
    <h1 class="text-3xl font-bold">{{ $t("interview.title") }}</h1>
    <!-- BEGIN: Data List -->
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-nowrap
          items-center
          mt-2
        "
      >
        <div
          class="
            w-full
            sm:w-auto
            mt-3
            sm:mt-0 sm:ml-auto
            md:ml-0
            flex
            items-center
          "
        >
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('search')"
              v-model="search"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <select v-model="limit" class="w-20 form-select box ml-4">
            <option v-for="item in limitOpt" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ total }} {{ $t("dan") }} {{ list.length }} {{ $t("showingOf") }}
        </div>
        <div class="form-check mr-4 p-2 bg-white rounded-lg shadow-sm">
          <input
            class="form-check-switch"
            type="checkbox"
            v-model="isArchive"
            id="archiveInterview"
          />
          <label class="form-check-label font-bold" for="archiveInterview">{{
            $t("archiveConv")
          }}</label>
        </div>
      </div>
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <app-loading :show="loading">
          <table class="table table-report -mt-2">
            <thead>
              <tr>
                <th class="w-3 text-center">#</th>
                <th class="whitespace-nowrap">
                  <CalendarIcon class="mr-2" /> {{ $t("field.date") }}
                </th>
                <th class="whitespace-nowrap text-center">
                  {{ $t("field.region") }}
                </th>
                <th class="whitespace-nowrap text-center">
                  {{ $t("field.phone") }}
                </th>
                <th class="text-center text-center" style="width: 300px">
                  {{ $t("field.text") }}
                </th>
                <th class="whitespace-nowrap text-center">
                  {{ $t("status.title") }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ $t("actions.title") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="list.length">
              <tr
                v-for="(item, index) in list"
                :key="index"
                class="intro-x"
                :class="{
                  warning: item.status == 'ATTACHED_CANDIDATE',
                  'warning font-bold': item.isSigned == false,
                }"
              >
                <td class="w-3 text-center">
                  {{ $paginate(index, page, limit) }}
                </td>
                <td>
                  <div class="text-theme-3 text-xs whitespace-nowrap text-base">
                    {{ item.date }} {{ item.time }}
                  </div>
                </td>
                <td class="text-center whitespace-nowrap">
                  {{ item[$localeKey("regionName")] }}
                </td>
                <td class="text-center whitespace-nowrap">{{ item.phone }}</td>
                <td class="text-center" style="width: 300px">
                  {{ item.reason }}
                </td>
                <td class="text-center w-20 whitespace-nowrap">
                  <div
                    class="flex items-center justify-center"
                    :class="
                      item.status == 'CLOSED'
                        ? 'text-theme-6'
                        : item.status == 'FINISHED'
                        ? 'text-theme-9'
                        : 'text-theme-1'
                    "
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    {{ $t(`status.${item.status}`) }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link
                      :to="`/interview/${$enc(item.id)}/add-commission`"
                      class="
                        btn
                        text-white
                        bg-theme-10
                        w-8
                        h-8
                        p-0
                        rounded-full
                      "
                    >
                      <UsersIcon class="w-4" />
                    </router-link>
                    <router-link
                      v-if="
                        item.status !== 'ATTACHED_ASSESSORS' &&
                        item.status !== 'ATTACHED_CANDIDATE'
                      "
                      :to="`/interview/${$enc(item.id)}/sheet`"
                      class="
                        btn
                        text-white
                        ml-2
                        bg-theme-29
                        w-8
                        h-8
                        p-0
                        rounded-full
                      "
                    >
                      <ActivityIcon class="w-4" />
                    </router-link>
                    <button
                      v-if="item.status == 'FINISHED'"
                      @click="openEditor(item)"
                      class="
                        btn
                        text-white
                        ml-2
                        bg-theme-29
                        w-8
                        h-8
                        p-0
                        rounded-full
                      "
                    >
                      <EditIcon class="w-4" />
                    </button>
                    <button
                      v-if="
                        item.status == 'PROTOCOL' ||
                        item.status == 'PROTOCOL_SIGN_PROCESS' ||
                        item.status == 'PROTOCOL_SIGNED'
                      "
                      @click="viewProtocol(item)"
                      class="
                        btn
                        text-white
                        ml-2
                        bg-theme-29
                        w-8
                        h-8
                        p-0
                        rounded-full
                      "
                    >
                      <EyeIcon class="w-4" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <not-found v-else colspan="7"></not-found>
          </table>
          <n-pagination
            v-model:page="page"
            :page-count="Math.ceil(total / limit)"
          />
        </app-loading>
      </div>
    </div>
    <DocEditor v-model:editorData="body" v-model:show="showEditor">
      <template #buttons>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <app-loading :show="protocolLoading">
              <button @click="createProtocol" class="btn btn-primary mr-2">
                <SendIcon class="w-4 mr-1" />
                {{ $t("sendCommision") }}
              </button>
            </app-loading>
            <app-loading :show="excelLoading">
              <button class="btn bg-theme-7 text-white mr-2" @click="getSheet">
                <DownloadIcon class="w-4 mr-1" /> {{ $t("pointSheet") }}
              </button>
            </app-loading>
            <button @click="viewPdf" class="btn btn-primary mr-2">
              <EyeIcon class="w-4 mr-1" />
              {{ $t("actions.viewPdf") }}
            </button>
          </div>
          <button class="btn bt-secondary" @click="showEditor = false">
            <XIcon class="w-4 mr-1" /> {{ $t("actions.close") }}
          </button>
        </div>
      </template>
    </DocEditor>
    <!-- END: Data List -->
    <SheetAll :sheetData="sheetData" ref="sheetAll" class="no-visible" />
    <ViewPdf ref="viewPdfRef" />
    <ViewPdf ref="viewPdfRefP" :noClosable="true">
      <template #buttons>
        <div class="flex items-center justify-between">
          <template v-if="!protocolItem.isSigned">
            <button @click="showSign = true" class="btn btn-primary mr-2">
              <EditIcon class="text-xs w-4 mr-2" />
              {{ $t("actions.imzolash") }}
            </button>
            <button @click="showEdit = true" class="btn btn-success mr-2">
              <Edit2Icon class="text-xs w-4 mr-2" />
              {{ $t("actions.edit") }}
            </button>
            <button @click="cancelShow = true" class="btn btn-danger mr-2">
              <XIcon class="text-xs w-4 mr-2" />
              {{ $t("actions.qaytarish") }}
            </button>
          </template>
          <app-loading
            v-if="
              currentItem.status == 'PROTOCOL_SIGNED' && !currentItem.isSend
            "
            :show="saveFileLoading"
          >
            <button @click="savePdfFile" class="btn btn-primary mr-2">
              <SendIcon class="text-xs w-4 mr-2" />
              {{ $t("actions.sendProtocol") }}
            </button>
          </app-loading>

          <app-loading :show="excelLoading">
            <button class="btn bg-theme-7 text-white mr-2" @click="getSheet">
              <DownloadIcon class="w-4 mr-1" /> {{ $t("pointSheet") }}
            </button>
          </app-loading>
        </div>
      </template>
    </ViewPdf>
    <n-modal
      v-model:show="showSign"
      preset="dialog"
      :title="$t('actions.imzolash')"
    >
      <app-loading :show="signLoading">
        <Signature @success="makeSinged" :selectedDoc="currentItem" />
      </app-loading>
    </n-modal>
    <!-- Edit protocol -->
    <DocEditor
      v-model:editorData="protocolBody"
      v-model:show="showEdit"
      :loading="editProtocolLoading"
    >
      <template #buttons>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <button @click="saveEditProtocol" class="btn btn-primary mr-2">
              {{ $t("actions.save") }}
            </button>
          </div>
          <button class="btn bt-secondary" @click="showEdit = false">
            <XIcon class="w-4 mr-1" /> {{ $t("actions.close") }}
          </button>
        </div>
      </template>
    </DocEditor>

    <!-- <c-text-editor v-model:value="text" :toolbar="'full'" /> -->
    <pre>
      {{ protocolBody }}
    </pre>
    <!-- close Protocol -->
    <n-modal
      v-model:show="cancelShow"
      preset="dialog"
      :title="$t('cancelReason')"
    >
      <app-loading :show="cancelLoading">
        <div class="flex flex-col items-center mt-12">
          <n-input
            v-model:value="cancelReason"
            type="textarea"
            :placeholder="$t('cancelReason')"
          />
          <div class="mt-6 flex justify-end items-center w-full">
            <button @click="cancelShow = false" class="btn btn-secondary mr-4">
              {{ $t("actions.cancel") }}
            </button>
            <button @click="cancelProtocol" class="btn btn-danger">
              {{ $t("actions.qaytarish") }}
            </button>
          </div>
        </div>
      </app-loading>
    </n-modal>
  </div>
</template>

<script setup>
import Signature from "@/components/main/signature";
import protocolHtml from "@/core/globals/viewProtocol";
import ViewPdf from "@/components/main/view-pdf";
import InterviewService from "@/services/interview.service";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const message = useMessage();
const { t } = useI18n();
const store = useStore();
import SheetAll from "./components/SheetAll.vue";
import DocEditor from "@/components/main/doc-editor.vue";
import CTextEditor from "@/components/hrm-components/CTextEditor.vue";
import GenerateProtocol from "./util/GenerateProtocol";
let showEditor = ref(false);
let currentItem = ref({});
let protocolBody = ref("");
let body = ref("<span>Hallooo</span>");
function openEditor(item) {
  currentItem.value = item;
  body.value = GenerateProtocol(item);
  showEditor.value = true;
}
let sheetAll = ref(null);
let excelLoading = ref(false);
let sheetData = ref({});
let text = ref("");
async function getSheet() {
  excelLoading.value = true;
  try {
    let res = await InterviewService.getSheet(currentItem.value.id);
    sheetData.value = res.data;
    setTimeout(() => {
      sheetAll.value.exportExcel();
    }, 500);
  } finally {
  }
  excelLoading.value = false;
}
// create protocol
let protocolLoading = ref(false);
async function createProtocol() {
  let payload = {
    documentId: currentItem.value.id,
    body: body.value,
  };
  try {
    protocolLoading.value = true;
    await InterviewService.protocolCreate(payload);
    showEditor.value = false;
    message.success(t("success.docSend"));
    getList();
  } finally {
    protocolLoading.value = false;
  }
}
// viewPdf
let viewPdfRef = ref(null);
function viewPdf() {
  viewPdfRef.value.view(body.value);
}
// view Protocol
let viewPdfRefP = ref(null);
let protocolItem = ref({});

let saveFileLoading = ref(false);
async function savePdfFile() {
  try {
    saveFileLoading.value = true;
    await InterviewService.savePdfFile({
      protocolId: currentItem.value.protocolId,
      base64: viewPdfRefP.value.base64,
    });
    message.success(t("success.docSend"));
    getList();
    viewPdfRefP.value.close();
  } finally {
    saveFileLoading.value = false;
  }
}
async function viewProtocol(item) {
  currentItem.value = item;
  loading.value = true;
  try {
    protocolItem.value = (
      await InterviewService.protocolById(item.protocolId)
    ).data;
    protocolBody.value = protocolItem.value.body;
    viewPdfRefP.value.view(protocolHtml(protocolItem.value));
  } finally {
    loading.value = false;
  }
}
// imzolash
let showSign = ref(false);
let signLoading = ref(false);
async function makeSinged(e) {
  signLoading.value = true;
  let payload = {
    id: currentItem.value.protocolId,
    ...e,
  };
  try {
    let res = await InterviewService.makeSignedProtocol(payload);
    if (res.data.closed) {
      try {
        let base64 = (
          await InterviewService.viewPdf(protocolHtml(res.data.dto))
        ).data;
        await InterviewService.savePdfFile({
          protocolId: currentItem.value.protocolId,
          base64,
        });
      } catch {
        message.error("Protokol yuborilmadi, iltimos qaytadan yuboring!");
      }
    }
    message.success(t("success.saved"));
    showSign.value = false;
    viewPdfRefP.value.close();
    getList();
  } finally {
    signLoading.value = false;
  }
}
//edit
let showEdit = ref(false);
let editProtocolLoading = ref(false);
async function saveEditProtocol() {
  editProtocolLoading.value = true;
  let payload = {
    id: currentItem.value.protocolId,
    body: protocolBody.value,
  };
  try {
    await InterviewService.editProtocol(payload);
    message.success(t("success.saved"));
    showEdit.value = false;
    viewPdfRefP.value.close();
    getList();
  } finally {
    editProtocolLoading.value = false;
  }
}
// cancel
let cancelShow = ref(false);
let cancelReason = ref("");
let cancelLoading = ref(false);
async function cancelProtocol() {
  let payload = {
    id: currentItem.value.protocolId,
    reason: cancelReason.value,
  };
  try {
    cancelLoading.value = true;
    await InterviewService.cancelProtocol(payload);
    message.success(t("success.saved"));
    cancelShow.value = false;
    viewPdfRefP.value.close();
    getList();
  } finally {
    cancelLoading.value = false;
  }
}
//
let list = ref([]);
let total = ref(0);
let loading = ref(false);
let limitOpt = [10, 20, 50, 100];
let limit = ref(20);
let search = ref("");
let page = ref(1);
let isArchive = ref(false);
let payload = computed(() => {
  return {
    search: search.value,
    isArchive: isArchive.value,
    params: {
      limit: limit.value,
      page: page.value - 1,
    },
  };
});

async function getList() {
  loading.value = true;
  try {
    let res = await InterviewService.listAll(payload.value);
    list.value = res.data.list;
    total.value = res.data.total;
  } finally {
    loading.value = false;
  }
}
watch(
  isArchive,
  (value) => {
    store.commit("main/setArchiveLawyer", value);
  },
  { deep: true }
);

watch(
  payload,
  () => {
    getList();
  },
  { deep: true }
);
onMounted(() => {
  getList();
  isArchive.value = store.state.main.archiveLawyer;
});

//
</script>
<style>
.no-visible {
  position: absolute;
  z-index: -5555;
  opacity: 0;
  visibility: hidden;
  opacity: 0;
}
</style>
<style scoped lang="scss">
.warning {
  td {
    background: rgba(247, 139, 0, 0.4) !important;
  }
}
</style>
