<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ $t("references") }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: FAQ Menu -->
      <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
        <div class="box mt-5" style="min-height: 500px">
          <div class="px-4 pb-3 pt-5 ref-link">
            <router-link
              to="/reference/document"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("requiredDocs") }}</div>
            </router-link>
            <!-- <router-link
              to="/reference/lisence-type"
              class="flex items-center px-4 py-2"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("lisenceType") }}</div>
            </router-link> -->
            <router-link
              to="/reference/region"
              class="flex items-center px-4 py-2"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("field.region") }}</div>
            </router-link>
            <router-link
              to="/reference/staff-position"
              class="flex items-center px-4 py-2"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("field.staffPosition") }}</div>
            </router-link>
            <router-link
              to="/reference/contragent"
              class="flex items-center px-4 py-2"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("field.contragent") }}</div>
            </router-link>
            <router-link
              to="/reference/commission-type"
              class="flex items-center px-4 py-2"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("field.comType") }}</div>
            </router-link>
            <router-link
              to="/reference/question-type"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("questionType") }}</div>
            </router-link>
            <router-link
              to="/reference/question-type-count"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("questionTypeCount") }}</div>
            </router-link>
            <router-link
              to="/reference/question"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("questions") }}</div>
            </router-link>
            <router-link
              to="/reference/role"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("field.role") }}</div>
            </router-link>
            <router-link
              to="/reference/permission"
              class="flex items-center px-4 py-2 mt-1"
            >
              <SettingsIcon class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate">{{ $t("permissions") }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: FAQ Menu -->
      <!-- BEGIN: FAQ Content -->
      <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.ref-link .router-link-exact-active {
  @apply bg-theme-1 text-white font-medium rounded-lg;
}
</style>