<template>
  <div class="grid grid-cols-11 px-2 py-3 bg-white">
    <div class="col-span-1"></div>
    <div class="col-span-2 border-r flex items-center">
      <ChevronRightIcon
        class="w-4 -ml-12 cursor-pointer right-chev"
        :class="{ 'down-chev': show }"
        @click="show = !show"
        v-if="item.children.length"
      />
      <div class="ml-2">
        {{ item.nameUz }}
      </div>
    </div>
    <div class="col-span-2 border-r text-center">{{ item.nameLt }}</div>
    <div class="col-span-2 border-r text-center">{{ item.nameRu }}</div>
    <div class="col-span-2 border-r text-center">{{ item.nameEn }}</div>
    <div class="col-span-2 text-center">
      <button
        @click="$emit('add', item)"
        class="btn btn-primary-soft w-8 h-8 p-0 rounded-full"
      >
        <PlusIcon class="w-4" />
      </button>
      <button
        class="btn btn-primary w-8 h-8 p-0 rounded-full ml-2"
        @click="$emit('edit', item)"
      >
        <EditIcon class="w-4" />
      </button>
      <button
        @click="$emit('delete', item)"
        class="btn btn-danger w-8 h-8 p-0 rounded-full ml-2"
      >
        <TrashIcon class="w-4" />
      </button>
    </div>
  </div>
  <n-collapse-transition :show="show">
    <Treeview
      v-for="(child, i) in item.children"
      :key="i"
      :item="child"
      @add="add"
      @delete="deleteItem"
      @edit="edit"
    />
  </n-collapse-transition>
</template>
<script>
export default {
  name: "Treeview",
};
</script>
<script setup>
import { ref } from "vue";
const props = defineProps({
  item: {
    default: () => {},
  },
});
const emits = defineEmits(["edit", "add", "delete"]);
let show = ref(false);
function edit(e) {
  emits("edit", e);
}
function add(e) {
  emits("add", e);
}
function deleteItem(e) {
  emits("delete", e);
}
</script>

<style>
.right-chev {
  transition: all ease 0.3s;
}
.right-chev.down-chev {
  transform: rotate(90deg);
}
</style>