<template>
  <div>
    <n-scrollbar trigger="none" :x-scrollable="true" id="printTarget" class="pb-6">
      <table striped class="p-5 table border border-theme-7 bg-white rounded" v-if="sheetData?.candidates?.length"
        id="tbExcel2">
        <tr>
          <!---->
          <th rowspan="2" style="width: 4%; vertical-align: middle">№</th>
          <th rowspan="2" style="width: 40%; vertical-align: middle">
            Номзоднинг ФИОси
          </th>
          <th colspan="6" rowspan="1" v-for="(item, index) in sheetData.candidates[0].assessors" :key="index">
            {{ item.assessorFullName }}
            <br /><strong class="text-theme-1">
              {{ item[$localeKey("interviewAppraiserName")] }}
            </strong>
          </th>

          <th rowspan="2" class="bg-theme-3 bg-opacity-10" style="width: 10%; vertical-align: middle; font-weight: bold"
            v-if="sheetData.who == 'OWNER'">
            Jami ball
          </th>
          <th rowspan="2" style="width: 10%; vertical-align: middle; font-weight: bold"
            v-if="sheetData.who == 'OWNER' && sheetData.status == 'FINISHED'">
            Foiz (%)
          </th>
          <th rowspan="2" style="width: 10%; vertical-align: middle" class="hide-print">
            Амаллар
          </th>
        </tr>
        <tr>
          <template v-for="(item, index) in sheetData.candidates[0].assessors" :key="index">
            <th style="vertical-align: middle">
              1-йўналиш <br />Лавозим касб фаолиятига боғлиқ қонунчилик
              <br />(0-10 балл)
            </th>
            <th style="vertical-align: middle">
              2-йўналиш <br />Умумий қонунчилик соҳалари <br />(0-10 балл)
            </th>
            <th style="vertical-align: middle">
              3-йўналиш <br />Умумий қонунчилик соҳалари <br />(0-10 балл)
            </th>
            <th style="vertical-align: middle">
              4-йўналиш <br />Умумий қонунчилик соҳалари <br />(0-10 балл)
            </th>
            <th style="vertical-align: middle">
              5-йўналиш <br />Казуслар <br />(0-10 балл)
            </th>
            <th class="font-bold text-center" style="vertical-align: middle; font-weight: bold">
              Jami
            </th>
          </template>
        </tr>
        <tr v-for="(item, index) in sheetData.candidates" :key="index" :class="{
          'bg-gray-200 dark:bg-dark-1': i % 2 == 0,
          passed:
            item.lastOverAllInProportion >= 56 &&
            sheetData.status == 'FINISHED',
          failed:
            item.lastOverAllInProportion < 56 &&
            sheetData.status == 'FINISHED',
        }">
          <!---->
          <td>{{ index + 1 }}</td>
          <td class="tdPrint" style="vertical-align: middle">
            <span @click="getInfoCandidate(item)" class="cursor-pointer">
              {{ item.candidateFullName }}</span>
            <div class="text-theme-1 text-center mx-auto" style="width: 175px">
              ( {{ item[$localeKey('licenceTypeName')] }})
            </div>
          </td>
          <template v-for="assessor in item.assessors" :key="assessor.id">
            <td style="vertical-align: middle" v-for="index2 in 5" :key="index2">
              <n-input-number v-if="
                sheetData.who !== 'OWNER' &&
                sheetData.candidates?.[0].assessors[0].status ==
                'ATTACHED_ASSESSORS'
              " v-maska="'##'" v-model:value.lazy="assessor[`b${index2}`]" placeholder="" :min="0" :max="10"
                :show-button="false" style="min-width: 80px" @focus="currentBall = assessor[`b${index2}`]"
                @focusout="setMark(item, assessor, index2)" />
              <span v-else> {{ assessor[`b${index2}`] }} </span>
            </td>
            <td class="font-bold">{{ assessor.overAll }}</td>
          </template>
          <td v-if="sheetData.who == 'OWNER'" class="bg-theme-3 bg-opacity-10 font-bold">
            {{ item.lastOverAll }}
          </td>
          <td v-if="sheetData.who == 'OWNER' && sheetData.status == 'FINISHED'" style="word-break: keep-all !important">
            {{ item.lastOverAllInProportion }}%
          </td>
          <td class="hide-print" style="vertical-align: middle">
            <div class="flex items-center">
              <button @click="questionGenerate(item)" type="button" class="btn btn-primary">
                {{ $t("questions") }}
              </button>
              <button @click="getInfoCandidate(item)" class="btn btn-facebook ml-3">
                <UserIcon class="w-5 h-5" />
              </button>
            </div>
          </td>
        </tr>
      </table>
    </n-scrollbar>

    <n-modal v-model:show="questionShow" :mask-closable="false" :title="$t('questions')" :auto-focus="false">
      <app-loading :show="loading" icon="oval">
        <n-card style="width: 700px" id="question">
          <div class="flex justify-end">
            <button :disabled="!allGenerated" class="btn btn-primary-soft hide-print" @click="printIt">
              <PrinterIcon class="mr-2 w-4" /> {{ $t("actions.print") }}
            </button>
          </div>
          <div class="mb-5 text-theme-10 text-xl font-bold">
            {{ currentCandidate.candidateFullName }}
          </div>
          <n-steps vertical :current="currentStep" :status="'process'">
            <n-step v-for="(type, index) in currentQuestions" :key="index" @click="getQuestion(type, index)"
              :class="{ 'cursor-pointer': !type.question }" :description="type.question"
              :status="type.question ? 'process' : 'wait'" :disabled="type.question">
              <template #title>
                <div class="font-bold mb-1 flex justify-between items-center">
                  <div>{{ type[$localeKey()] }}</div>
                  <n-tooltip trigger="click" class="hide-print" v-if="sheetData.who !== 'OWNER'">
                    <template #trigger>
                      <AlertCircleIcon class="w-5 h-5 rounded-full ml-5 hide-print"
                        style="pointer-events: all !important; cursor: pointer" />
                    </template>
                    {{ type.answer }}
                  </n-tooltip>
                </div>
                <div class="text-sm text-black text-opacity-50">
                  {{ type.date }}
                </div>
              </template>
            </n-step>
          </n-steps>
          <div class="flex items-center justify-end mt-5 hide-print">
            <button :disabled="!allGenerated" @click="questionShow = false" class="btn btn-primary ml-4"
              :class="{ 'opacity-50 cursor-not-allowed': !allGenerated }">
              {{ $t("actions.close") }}
            </button>
          </div>
        </n-card>
      </app-loading>
    </n-modal>
    <!-- Candidate Info -->
    <n-modal v-model:show="showCandidateInfo" preset="dialog" class="extra-modal bg-theme-2">
      <app-loading :show="loading" class="">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="relative flex items-center p-5">
              <div class="w-24 h-24 image-fit flex-shrink-0">
                <n-image v-if="candidateInfo.uploadPath" class="rounded-full"
                  :src="`${$baseUrl}/${candidateInfo.uploadPath}`" />
                <img v-else class="rounded-full" src="" alt />
              </div>
              <div class="ml-4 mr-auto">
                <div class="font-medium text-base">
                  {{ candidateInfo.candidateFullName }}
                </div>
                <div class="text-theme-1 text-opacity-60">
                  {{ candidateInfo.candidateMobilePhone }}
                </div>
              </div>
            </div>
            <div class="box border-t border-gray-200 dark:border-dark-5" style="min-height: 500px">
              <div class="px-4 pb-3 pt-5 ref-link">
                <button class="flex items-center px-4 py-2 mt-1 one-line w-full" :class="{
                  'bg-theme-1 text-white font-medium rounded-lg':
                    showPassportData,
                }" @click="showPassportData = true">
                  <div class="flex-1 truncate">
                    {{ $t("passportData") }}
                  </div>
                </button>
                <button v-for="(file, index) in candidateInfo.file" :key="index"
                  class="flex items-center px-4 py-2 mt-1 one-line w-full" :class="{
                    'bg-theme-1 text-white font-medium rounded-lg':
                      file.id == currentFile.id && !showPassportData,
                  }" @click="
                    currentFile = { ...file };
                  showPassportData = false;
                  ">
                  <div class="flex-1 truncate">
                    {{ file[$localeKey("docTypeName")] }}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9" v-if="currentFile.fileType && !showPassportData">
            <div class="mt-4 mb-2 text-theme-1 text-xl">
              {{ currentFile[$localeKey("docTypeName")] }}
            </div>
            <file-view :file="currentFile" />
          </div>
          <div v-else class="intro-y col-span-12 lg:col-span-8 xl:col-span-9 mt-8">
            <div class="
                bg-white
                rounded
                shadow-sm
                w-full
                p-4
                grid grid-cols-2
                gap-4
              ">
              <ul class="border rounded-md border-opacity-10 border-dark-3">
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.fio") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateFullName }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.passportSeria") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportNumber }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">
                    {{ $t("field.passportGivendate") }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportGivenDate }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">
                    {{ $t("field.passportEnddate") }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportEndDate }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.pinfl") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePNFL }}
                  </div>
                </li>
              </ul>
              <ul class="border rounded-md border-opacity-10 border-dark-3">
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.nationality") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateNationality }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.birthCountry") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateBirthAddress }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.address") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateAddress }}
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.phone") }}</div>
                  <div class="col-span-2 text-right">
                    <a class="text-theme-4 text-opacity-75" :href="`tel:${candidateInfo.candidateMobilePhone}`">
                      {{ candidateInfo.candidateMobilePhone }}
                    </a>
                  </div>
                </li>
                <li class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  ">
                  <div class="col-span-1">{{ $t("field.email") }}</div>
                  <div class="col-span-2 text-right">
                    <a class="text-theme-4 text-opacity-75" :href="`tel:${candidateInfo.candidateEmail}`">
                      {{ candidateInfo.candidateEmail }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </app-loading>
    </n-modal>
  </div>
</template>

<script setup>
import FileView from "@/components/main/file-view.vue";

import InterviewService from "@/services/interview.service.js";
import CandidateService from "@/services/candidate/Candidate.service.js";
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
  defineExpose,
} from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
import Util from "@/core/globals/globalMethods.js";
const message = useMessage();
const props = defineProps({
  sheetData: {
    default: () => { },
  },
});
const { t, locale } = useI18n();
let currentBall = ref(null);

async function setMark(item, assessor, index) {
  if (currentBall.value !== assessor[`b${index}`]) {
    let payload = {
      currentId: assessor.assessorId,
      docId: assessor.documentId,
      candidateId: item.id,
      [`b${index}`]: assessor[`b${index}`] || 0,
    };
    try {
      let res = await InterviewService.marking(payload);
      assessor.overAll = res.data.overAll;
      item.lastOverAll = res.data.lastOverAll;
    } catch { }
  }
}

let questionShow = ref(false);
let currentQuestions = ref([]);
let currentCandidate = ref({});
let loading = ref(false);
async function questionGenerate(item) {
  currentCandidate.value = item;
  currentQuestions.value = [];
  questionShow.value = true;
  loading.value = true;
  try {
    let res = await InterviewService.getQuestionTypeCount({ licenceId: item.licenceTypeId, candidateId: item.id });
    let action =
      props.sheetData.who == "OWNER"
        ? "getQuestionByCandidate"
        : "getQuestionWithAnswer";
    let resQues = await InterviewService[action](item.id);
    currentQuestions.value = res.data;
    currentQuestions.value = currentQuestions.value.map((ques) => {
      let ranQues = resQues.data.filter(
        (el) => el.questionTypeId == ques.id
      )[0];
      return {
        ...ques,
        question: ranQues?.[Util.localeKey("questionName")] || null,
        date: ranQues?.date,
        answer: ranQues?.[Util.localeKey("answerName")] || null,
      };
    });
  } finally {
    loading.value = false;
  }
}

let currentStep = ref(0);
async function getQuestion(type, index) {
  if (type.question || props.sheetData.who !== "OWNER") return;
  currentStep.value = index + 1;
  loading.value = true;
  try {
    let res = await InterviewService.randomQuestion({
      candidateId: currentCandidate.value.id,
      typeId: type.id,
    });
    currentQuestions.value[index].question = res.data[Util.localeKey()];
  } finally {
    loading.value = false;
  }
}

let allGenerated = computed(() =>
  currentQuestions.value.every((el) => el.question)
);

let showCandidateInfo = ref(false);
let candidateInfo = ref({});
let currentFile = ref({});
let showPassportData = ref(true);
async function getInfoCandidate(item) {
  showCandidateInfo.value = true;
  try {
    loading.value = true;
    let res = await CandidateService.getCandidateId(item.lawyerCandidateId);
    candidateInfo.value = res.data[0] || {};
    currentFile.value = candidateInfo.value.file?.[0] || {};
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  // window.onbeforeunload = function (e) {
  //   e = e || window.event;
  //   if (e) {
  //     e.returnValue = "Sure?";
  //   }
  //   return "Sure?";
  // };
});
onUnmounted(() => {
  window.onbeforeunload = () => { };
});

function printIt() {
  const prtHtml = document.getElementById("question").innerHTML;
  let stylesHtml = "";
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style'),
  ]) {
    stylesHtml += node.outerHTML;
  }
  const WinPrint = window.open(
    "",
    "",
    "left=0,top=0,width=800,height=1000,toolbar=0,scrollbars=0,status=0"
  );
  WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
         
            #printBody {
              background:#fff!important;
              min-height:100vh!important;
            }
            .hide-print {
              display:none!important;
            }
          </style>
            ${stylesHtml}
          </head>
          <body id="printBody">
            ${prtHtml}
          </body>
        </html>`);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.onload = function () {
    WinPrint.print();
    // WinPrint.close();
  };
}

function exportExcel() {
  var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
  var textRange;
  var j = 0;
  let tab = document.getElementById("tbExcel2"); // id of table
  let sa;
  for (j = 0; j < tab.rows.length; j++) {
    tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
  }

  tab_text = tab_text + "</table>";
  tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");
  tab_text = tab_text.replace(/<img[^>]*>/gi, "");
  tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, "");

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    txtArea1.document.open("txt/html", "replace");
    txtArea1.document.write(tab_text);
    txtArea1.document.close();
    txtArea1.focus();
    sa = txtArea1.document.execCommand(
      "SaveAs",
      true,
      "Say Thanks to Sumit.xls"
    );
  } else
    sa = window.open(
      "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
    );

  return sa;
}
defineExpose({
  exportExcel,
});
</script>

<style scoped>
.passed td {
  @apply bg-theme-4 bg-opacity-20;
}

.failed td {
  @apply bg-theme-6 bg-opacity-20;
}

table {
  width: 100%;
}

td,
th {
  @apply border-theme-3 border-opacity-30;
  text-align: center;
  border-left-width: 1px;
  border-bottom-width: 0;
  border-top-width: 1px;
}

table {
  @apply border border-theme-3;
}

tr.bg-danger td {
  @apply bg-theme-4 bg-opacity-50;
}
</style>

<style>
.n-dialog .n-dialog__close {
  z-index: 99999 !important;
}

.n-scrollbar>.n-scrollbar-rail.n-scrollbar-rail--horizontal>.n-scrollbar-rail__scrollbar {
  height: 10px !important;
}

.n-step--disabled {
  pointer-events: none;
}
</style>