import ApiService from "../api.service";
const main = "directoryLicenseType";

export default {
  getList({ params, search }) {
    return ApiService.post(`getInfo/list?search=${search || ""}`, params);
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id/${id}`);
  },
  create(data) {
    return ApiService.post(`${main}/create`, data);
  },
  update(data) {
    return ApiService.post(`${main}/update`, data);
  },
  delete(id) {
    return ApiService.delete(`${main}/delete/${id}`);
  },
};
