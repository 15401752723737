<template>
  <div class="mt-12">
    <div class="px-4 py-2 flex items-center justify-between">
      <router-link to="/interview" class="btn btn-primary-soft">
        <ArrowLeftIcon class="w-4" /> {{ $t("actions.back") }}</router-link
      >
      <div
        v-if="status == 'ATTACHED_ASSESSORS' || status == 'ATTACHED_CANDIDATE'"
      >
        <button class="btn btn-primary" @click="addShow = true">
          <UserPlusIcon class="w-4 mr-2" /> {{ $t("interview.addCommission") }}
        </button>
        <button
          class="btn text-white bg-theme-10 text- ml-4"
          @click="startShow = true"
        >
          <ClockIcon class="w-4 mr-2" /> {{ $t("interview.start") }}
        </button>
      </div>
    </div>
  </div>
  <div class="overflow-x-auto my-5 p-2 bg-white">
    <div class="text-2xl font-bold text-center mb-4">
      {{ $t("interview.commission") }}
    </div>
    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">{{ $t("field.fio") }}</th>
          <th class="whitespace-nowrap">{{ $t("field.staffPosition") }}</th>
          <th class="whitespace-nowrap">{{ $t("field.comType") }}</th>
          <th class="whitespace-nowrap w-10 text-center">
            {{ $t("actions.title") }}
          </th>
        </tr>
      </thead>
      <tbody v-if="assessors.length">
        <tr
          v-for="(item, index) in assessors"
          :key="index"
          class="hover:bg-gray-200"
        >
          <td class="border-b dark:border-dark-5">{{ index + 1 }}</td>
          <td class="border-b dark:border-dark-5">
            {{ item.assessorFullName }}
          </td>
          <td class="border-b dark:border-dark-5">{{}}</td>
          <td class="border-b dark:border-dark-5">
            {{ item[$localeKey("interviewAppraiserName")] }}
          </td>
          <td class="border-b dark:border-dark-5 w-10 text-center">
            <button
              v-if="
                status == 'ATTACHED_ASSESSORS' || status == 'ATTACHED_CANDIDATE'
              "
              @click="
                currentItem = item;
                deleteShow = true;
              "
              class="btn btn-danger w-8 h-8 p-0 rounded-full mr-2"
            >
              <TrashIcon class="w-4" />
            </button>
          </td>
        </tr>
      </tbody>
      <not-found v-else colspan="4"></not-found>
    </table>
  </div>
  <div class="overflow-x-auto p-2 bg-white">
    <div class="text-2xl font-bold text-center mb-4">
      {{ $t("interview.candidate") }}
    </div>
    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">{{ $t("field.fio") }}</th>
          <th class="whitespace-nowrap">{{ $t("field.region") }}</th>
          <th class="whitespace-nowrap">{{ $t("lisenceType") }}</th>
        </tr>
      </thead>
      <tbody v-if="candidates.length">
        <tr v-for="(item, index) in candidates" :key="index">
          <td class="border-b dark:border-dark-5">{{ index + 1 }}</td>
          <td class="border-b dark:border-dark-5">
            {{ item.candidateFullName }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ item[$localeKey("regionName")] }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ item[$localeKey("licenseTypeName")] }}
          </td>
        </tr>
      </tbody>
      <not-found v-else colspan="4"></not-found>
    </table>
  </div>
  <n-modal v-model:show="addShow" :auto-focus="false">
    <app-loading :show="loading" icon="oval">
      <n-card
        :title="$t('actions.add')"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        style="width: 850px"
      >
        <AddUpdate
          ref="addRef"
          @success="send"
          @close="addShow = false"
          :item="currentItem"
          :mode="mode"
        />
      </n-card>
    </app-loading>
  </n-modal>
  <n-modal
    v-model:show="deleteShow"
    :mask-closable="false"
    preset="dialog"
    :title="$t('actions.delete')"
    :content="$t('sureDelete')"
    :negative-text="$t('actions.cancel')"
    :positive-text="$t('actions.save')"
    @positive-click="deleteItem"
    @negative-click="deleteShow = false"
  />
  <n-modal
    v-model:show="startShow"
    :mask-closable="false"
    preset="dialog"
    :title="$t('actions.confirm')"
    :content="$t('interview.sureConfirm')"
    :negative-text="$t('actions.cancel')"
    :positive-text="$t('actions.confirm')"
    @positive-click="startInterview"
    @negative-click="startShow = false"
  />
</template>
<script setup>
import AddUpdate from "../components/AssessorForm.vue";
import Util from "@/core/globals/globalMethods";
import { useRoute, useRouter } from "vue-router";
import InterviewService from "@/services/interview.service";
import { useLoadingBar, useMessage } from "naive-ui";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const loadingBar = useLoadingBar();
const message = useMessage();
const { t } = useI18n();
const documentId = ref(Util.dec(route.params.docId));
let candidates = ref([]);
let assessors = ref([]);
let addShow = ref(false);
let loading = ref(false);
let currentItem = ref({});
let deleteShow = ref(false);
let startShow = ref(false);
async function getCandidates() {
  loadingBar.start();
  try {
    let res = await InterviewService.getCandidates(documentId.value);
    candidates.value = res.data;
  } finally {
    loadingBar.finish();
  }
}
async function getAssessors() {
  loading.value = true;
  loadingBar.start();
  try {
    let res = await InterviewService.getAssessors(documentId.value);
    assessors.value = res.data;
  } finally {
    loadingBar.finish();
    loading.value = false;
  }
}
onMounted(() => {
  getAssessors();
  getCandidates();
});

async function send(e) {
  let payload = {
    documentId: documentId.value,
    assessors: [...e],
  };
  try {
    loadingBar.start();
    await InterviewService.createAssessors(payload);
    addShow.value = false;
    getAssessors();
  } finally {
    loadingBar.finish();
  }
}
async function deleteItem() {
  try {
    loadingBar.start();
    await InterviewService.deleteAssessor(currentItem.value.id);
    message.success(t("success.saved"));
    deleteShow.value = false;
    getAssessors();
  } finally {
    loadingBar.finish();
  }
}
async function startInterview() {
  await InterviewService.start(documentId.value);
  message.success(t("interview.started"));
  router.push(`/interview/${route.params.docId}/sheet`);
}
let status = ref("");
async function getSheet() {
  let res = await InterviewService.getSheet(documentId.value);
  status.value = res.data.status;
}

onMounted(getSheet);
</script>

<style>
</style>