export function dmy(date) {
    let year = new Date(date).getFullYear()
    let month = new Date(date).getMonth() + 1
    let monthFormatted = month <= 9 ? '0' + month : month
    let days = new Date(date).getDate()

    return `${days}-${monthFormatted}-${year}`
}
export function hm(date) {
    let hours = new Date(date).getHours()
    let minutes = new Date(date).getMinutes()
    return `${hours}:${minutes}`
}