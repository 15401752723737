function protocolHtml(content) {
  let body = content.body;
  let date = new Date();
  let docNumber = content.regNumber || "";
  let assignments = content.assignments || [];
  let group = assignments.reduce((r, a) => {
    r[a.assignmentNameLt] = [...(r[a.assignmentNameLt] || []), a];
    return r;
  }, {});
  let wrap = document.createElement("div");
  wrap.innerHTML = body;
  let signedDate = wrap.querySelector("#date") ?? {};
  let regNumberHtml = wrap.querySelector("#regNumber") ?? {};
  if (date) {
    signedDate.innerText = `${date}`;
  }
  regNumberHtml.innerText = `${docNumber}-sonli`;
  if (!wrap.querySelector("#signers")) {
    wrap.innerHTML = wrap.outerHTML + "<div id='signers'></div>";
  }
  let signers = wrap.querySelector("#signers");
  let signerHtml = "";
  for (const key in group) {
    if (Object.hasOwnProperty.call(group, key)) {
      const signerArr = group[key];
      signerHtml += orderArrayHtml(
        signerArr,
        key == "null" ? "Ishchi organ kotibi" : key
      );
    }
  }
  signers.innerHTML = `<div style="margin-top:50px!important;"> ${signerHtml} </div>`;
  return wrap.outerHTML;
}
function protocolSignerHtml(signer, title) {
  if (!signer) return "";
  return `<div  class="kk-item ">
               <div  class="kk-left"><strong> ${title} </strong></div>
            <div  class="kk-right "> ${signer.signerShortName} <div style="width:50px;margin-left:15px!important; display:inline-block"> <img style="width:100%"  src="data:image/png;base64,${signer.qrCode}"/> </div> </div>
            </div>`;
}
function orderArrayHtml(arr, title) {
  if (!arr) return "";
  arr = Array.from(arr);
  if (arr.length == 0) return "";
  let content = `<p style="padding:10px 0!important;"><strong style="font-size:16pt"> ${title} </strong></p>`;
  arr.forEach((el) => {
    content += `<div  class="kk-item">
               <div  class="kk-left"></div>
            <div  class="kk-right "> ${el.signerFullName} <div style="width:50px; flex-shirnk:0; margin-left:15px!important; display:inline-block"> <img style="width:100%;flex-shirnk:0;"  src="data:image/png;base64,${el.qrCode}"/> </div> </div>
            </div>`;
  });
  let wrap = document.createElement("div");
  wrap.innerHTML = content;
  return wrap.outerHTML;
}
export default protocolHtml;
