<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    style="max-width: 700px"
  >
    <n-form-item :label="$t('name.uz')" path="nameUz">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameUz"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('name.lt')" path="nameLt">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameLt"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('name.en')" path="nameEn">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameEn"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('name.ru')" path="nameRu">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameRu"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('numberOfAttachments')" path="limit">
      <!-- <n-input
        :type="inputType"
        v-model:value="formValue.nameRu"
        placeholder=""
      /> -->
      <n-input-number
        class="w-full"
        v-model:value="formValue.limits"
        :placeholder="$t('numberOfAttachments')"
        :min="0"
        :max="10"
        clearable
      />
    </n-form-item>

    <n-form-item class="flex items-center justify-end mt-4">
      <button @click="$emit('close')" class="btn btn-secondary">
        {{ $t("actions.cancel") }}
      </button>
      <button @click="validate" class="btn btn-primary ml-4">
        {{ $t("actions.save") }}
      </button>
    </n-form-item>
  </n-form>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";

const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
  inputType: {
    default: "textarea",
  },
});
let formValue = ref({
  nameUz: "",
  nameLt: "",
  nameEn: "",
  nameRu: "",
  limits: 0,
});

let payload = ref({
  page: 0,
  limit: 50,
});
let regionsUz = ref([]);
onMounted(() => {});

let rules = {
  nameUz: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameLt: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameEn: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameRu: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  limits: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};

onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
  }
});
function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      emits("success", formValue.value);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style></style>
