<template>
  <div>
    <n-modal v-model:show="signModal">
      <h4>{{ modalTitle }}</h4>
      <div>
        <button
          @click="hideModal('signModal')"
          class="btn btn-secondary shadow-md mr-2"
        >
          {{ $t("actions.cancel") }}
        </button>

        <button @click="signByEimzo" class="btn btn-primary shadow-md mr-2">
          {{ $t("actions.imzolash") }}
        </button>
      </div>
    </n-modal>
    <div class="p-3">
      <div>
        <label class="mt-2">{{ $t("actions.chooseKey") }}</label>
        <v-select
          class="style-choos-contract"
          v-model="selectedKey"
          :options="keysList"
          label="CN"
        >
        </v-select>
      </div>
    </div>
    <div class="p-3 mt-3 mb-3">
      <div>
        <app-loading :show="loadingButton">
          <button
            @click.prevent="getData"
            :disabled="!selectedKey"
            class="
              btn btn-outline-primary
              w-full
              hover:bg-primary-1 hover:text-white
            "
          >
            {{ $t("actions.imzolash") }}
            <EditIcon class="text-xs w-4 ml-2" />
          </button>
        </app-loading>
      </div>
    </div>
  </div>
</template>

<script>
var EIMZO_MAJOR = 3;
var EIMZO_MINOR = 37;

const errorCAPIWS =
  "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
const errorBrowserWS =
  "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
const errorUpdateApp =
  'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
const errorWrongPassword = "Пароль неверный.";

import AppSettings from "@/core/settings";
import { UserService } from "@/services/user.service";
import { useToast } from "vue-toast-notification";
const $toast = useToast({
  position: "top-right",
});

export default {
  name: "Signature",
  props: ["selectedDoc", "givenDate", "withComment"],
  data() {
    return {
      comment: "",
      keysList: [],
      loading: false,
      selectedKey: null,
      loadingButton: false,
      signModal: false,
      currentItem: null,
      modalTitle: null,
      dataToSignIfNot: null,
      dataToSign: null,
    };
  },
  async created() {
    await this.appLoad();
  },
  methods: {
    async sendToBackendSignedData(signedData) {
      try {
        const payload = {
          signedContent: signedData,
          inn: this.selectedKey.TIN,
          pnfl: this.selectedKey.PINFL,
        };
        if (this.withComment) {
          await this.$emit("success", { ...payload, comment: this.comment });
        } else {
          await this.$emit("success", payload);
        }
      } catch (error) {}
    },
    hideModal(modal) {
      this.$data[modal] = false;
    },

    async openSignModal(item) {
      this.currentItem = item;
      this.modalTitle = item.serialNumber + " - " + item.CN;
      if (this.dataToSign === null) {
        await UserService.getKeyForLoginSign(item.TIN, item.PINFL).then(
          (response) => {
            this.dataToSignIfNot = response.data;
            this.signByEimzo();
          }
        );
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie(name) {
      document.cookie =
        name + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    setCookie(cname, cvalue) {
      var now = new Date();
      var time = now.getTime();
      time += 3600 * 6000;
      now.setTime(time);
      let expires = "expires=" + now.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    async signByEimzo(data2) {
      this.loadingButton = true;
      let vm = this;
      var data = JSON.stringify(this.selectedDoc);

      const promise = new Promise((resolve, reject) => {
        let keyId = this.getCookie("keyId-" + vm.currentItem.TIN);
        if (keyId == "") {
          EIMZOClient.loadKey(
            vm.currentItem,
            function (id) {
              EIMZOClient.createPkcs7(
                id,
                data,
                null,
                function (pkcs7) {
                  vm.setCookie("keyId-" + vm.currentItem.TIN, id);
                  resolve(pkcs7);
                },
                function (e, r) {
                  reject("error");
                  vm.loadingButton = false;
                  if (r) {
                    if (r.indexOf("BadPaddingException") != -1) {
                      $toast.error(errorWrongPassword);
                    } else {
                      $toast.error(r);
                    }
                  } else {
                    document.getElementById("keyId").innerHTML = "";
                    $toast.error(errorBrowserWS);
                  }
                  if (e) vm.wsError(e);
                }
              );
            },
            function (e, r) {
              reject("error");
              vm.loadingButton = false;
              if (r) {
                if (r.indexOf("BadPaddingException") != -1) {
                  $toast.error(errorWrongPassword);
                } else {
                  $toast.error(r);
                }
              } else {
                vm.$showMsgErro(errorBrowserWS);
              }
              if (e) vm.wsError(e);
            }
          );
        } else {
          EIMZOClient.createPkcs7(
            keyId,
            data,
            null,
            function (pkcs7) {
              resolve(pkcs7);
            },
            function (e, r) {
              reject("error");
              vm.loadingButton = false;
              if (r) {
                vm.clearCookie("keyId-" + vm.currentItem.TIN);
                if (r.indexOf("BadPaddingException") != -1) {
                  $toast.error(errorWrongPassword);
                } else {
                  $toast.error(r);
                }
              } else {
                document.getElementById("keyId").innerHTML = "";
                $toast.error(errorBrowserWS);
              }
              if (e) vm.wsError(e);
            }
          );
        }
      });
      promise
        .then(
          async (success) => {
            await vm.sendToBackendSignedData(success);
          },
          (error) => {
            $toast.error("Server error...");
          }
        )
        .finally(() => {
          vm.signModal = false;
          vm.loadingButton = false;
        });
    },
    async getData() {
      await this.openSignModal(this.selectedKey);
    },
    uiCreateItem(itmkey, vo) {
      let vm = this;
      var now = new Date();
      vo.expired = dates.compare(now, vo.validTo) > 0;
      var itm = document.createElement("option");
      itm.value = itmkey;
      itm.text = vo.CN;
      if (!vo.expired) {
      } else {
        itm.style.color = "gray";
        itm.text = itm.text + " (срок истек)";
      }
      vm.keysList.push(vo);
      itm.setAttribute("vo", JSON.stringify(vo));
      itm.setAttribute("id", itmkey);
      return itm;
    },
    async uiLoadKeys() {
      let vm = this;
      EIMZOClient.listAllUserKeys(
        function (o, i) {
          var itemId = "itm-" + o.serialNumber + "-" + i;
          return itemId;
        },
        function (itemId, v) {
          return vm.uiCreateItem(itemId, v);
        },
        function (items, firstId) {
          //vm.uiFillCombo(items);
          //vm.uiComboSelect(firstId);
        },
        function (e, r) {
          $toast.error(errorCAPIWS);
        }
      );
    },
    appLoad() {
      let vm = this;
      vm.loading = true;
      EIMZOClient.API_KEYS = AppSettings.api_keys;
      EIMZOClient.checkVersion(
        function (major, minor) {
          var newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
          var installedVersion = parseInt(major) * 100 + parseInt(minor);
          if (installedVersion < newVersion) {
            vm.loading = false;
            $toast.error(errorUpdateApp);
          } else {
            EIMZOClient.installApiKeys(
              function () {
                vm.loading = false;
                vm.uiLoadKeys();
              },
              function (e, r) {
                vm.loading = false;
                if (r) {
                  $toast.error(r);
                } else {
                  vm.wsError(e);
                }
              }
            );
          }
        },
        function (e, r) {
          vm.loading = false;
          if (r) {
            $toast.error(r);
          } else {
            $toast.error(e);
          }
        }
      );
    },
  },
};
</script>
<style>
.style-choos-contract .vs__search::placeholder,
.style-choos-contract .vs__dropdown-toggle,
.style-choos-contract .vs__dropdown-menu {
  border: 1px solid #e5eaee;
  padding: 8px;
  color: #394066;
  background: white;
}
</style>
