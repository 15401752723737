const state = () => {
  return {
    menu: [
      "devider",
      {
        icon: "HomeIcon",
        pageName: "Dashboard",
        title: "menu.requests",
        view: "View Main Menu",
      },
      {
        icon: "FileTextIcon",
        pageName: "Interview-view",
        title: "menu.interview",
        view: "View Conversations",
      },
      {
        icon: "UserIcon",
        pageName: "User",
        title: "menu.users",
        view: "View Users",
      },
      {
        icon: "UsersIcon",
        pageName: "Comissions",
        title: "menu.comissions",
        view: "View Users",
      },
      {
        icon: "SettingsIcon",
        pageName: "Document",
        title: "menu.reference",
        view: "View Settings",
      },
      {
        icon: "FileTextIcon",
        pageName: "FailedList",
        title: "menu.failedList",
        view: "Failed List",
      },
    ],
  };
};

// getters
const getters = {
  menu: (state) => state.menu,
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
