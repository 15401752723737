<template>
  <div>
    <QuillEditor
      class="w-full h-full"
      v-model:content="inputValue"
      contentType="html"
      :modules="modules"
      :toolbar="toolbar"
      theme="snow"
    >
    </QuillEditor>
  </div>
</template>

<script>
/* eslint-disable  */
import { QuillEditor } from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
// OR | AND
import "@vueup/vue-quill/dist/vue-quill.bubble.css";

export default {
  components: {
    QuillEditor,
  },
  props: {
    toolbar: {
      default: "",
    },
    value: {
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
  setup: () => {
    const modules = {
      name: "blotFormatter",
      module: BlotFormatter,
      options: {
        /* options */
      },
    };
    return { modules };
  },
};
</script>

<style>
</style>