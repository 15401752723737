<template>
  <div class="w-80 p-5 flex flex-col justify-center" v-if="type !== 'pdf'">
    <div
      v-if="type == 'zip' || type == 'rar'"
      class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
    >
      <span class="w-3/5 file__icon file__icon--directory mx-auto"></span
      ><span class="block font-medium mt-4 text-center truncate">
        {{ file.fileName }}
      </span>
      <!-- <div class="text-gray-600 text-xs text-center mt-0.5">112 GB</div> -->
    </div>
    <div
      v-else-if="type == 'jpg' || type == 'png' || type == 'jpeg'"
      class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
    >
      <span class="w-3/5 file__icon file__icon--image mx-auto"
        ><div class="file__icon--image__preview image-fit">
          <n-image :src="`${$baseUrl}/${file.uploadPath}`" /></div></span
      ><span class="block font-medium mt-4 text-center truncate">{{
        file.fileName
      }}</span>
      <!-- <div class="text-gray-600 text-xs text-center mt-0.5">1.4 MB</div> -->
    </div>
    <div
      class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
      v-else
    >
      <span class="w-3/5 file__icon file__icon--file mx-auto"
        ><div class="file__icon__file-name">{{ type }}</div></span
      ><span class="block font-medium mt-4 text-center truncate">{{
        file.fileName
      }}</span>
      <!-- <div class="text-gray-600 text-xs text-center mt-0.5">1 KB</div> -->
    </div>
    <a
      :download="`${$baseUrl}/${file.uploadPath}`"
      :href="`${$baseUrl}/${file.uploadPath}`"
      class="btn btn-primary-soft mt-4 mx-auto"
    >
      <DownloadIcon class="w-4 h-4 mr-2" /> {{ $t("actions.download") }}
    </a>
  </div>
  <div v-else>
    <embed
      :src="`${$baseUrl}/${file.uploadPath}`"
      type="application/pdf"
      style="width: 100%; height: 75vh"
    />
  </div>
</template>

<script setup>
let props = defineProps(["file"]);

let type =
  props.file?.fileType || props.file.uploadPath?.split(".").slice(-1)[0];
</script>

<style>
</style>