import ApiService from "./api.service";
const main = "candidateDocument";

export default {
  changeDocStatus(ids, file) {
    return ApiService.post(`${main}/changeDocStatus?docIds=${ids}`, file);
  },
  list({ search, params }) {
    return ApiService.post(`${main}/failed-list?search=${search}`, params);
  },
};
