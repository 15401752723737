<template>
  <n-drawer v-model:show="modelShow" :default-width="850" resizable>
    <n-drawer-content class="doc-editor">
      <app-loading :show="loading">
        <div class="mb-4 w-full">
          <slot name="buttons"></slot>
        </div>
        <ckeditor
          class="h-full w-full"
          :editor="ClassicEditor"
          v-model="modelValue"
          :config="editorConfig"
          @input="showInput"
        ></ckeditor>
        <!-- <pre>
          {{ modelValue }}
        </pre> -->
      </app-loading>
    </n-drawer-content>
  </n-drawer>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";
let props = defineProps({
  editorData: {
    default: "<span></span>",
  },
  show: {
    default: false,
  },
  loading: {
    default: false,
  },
});
let emits = defineEmits(["update:editorData"]);
let modelValue = computed({
  get() {
    return props.editorData;
  },
  set(val) {
    emits("update:editorData", val);
  },
});
let modelShow = computed({
  get() {
    return props.show;
  },
  set(val) {
    emits("update:show", val);
  },
});
let editorConfig = ref({});
</script>

<style lang="scss">
.doc-editor .ck-content {
  height: calc(100vh - 100px) !important;
}
.doc-editor {
  .ck-editor,
  .document-editor,
  .ck-editor__editable {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .ck.ck-content.ck-editor__editable_inline {
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    border-width: 1px;
  }
  .ck-editor__editable {
    width: 15.8cm;
    margin: 0 auto;
    min-height: 21cm;
    background: white;
    padding: 1cm 2cm 2cm;
    border-radius: var(--ck-border-radius);
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    border-width: 1px;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .ck.ck-editor__editable_inline[dir="ltr"] {
    text-align: left;
  }
  .ck-editor__main {
    overflow-y: scroll;
    padding: calc(2 * var(--ck-spacing-large));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
  }
}
</style>