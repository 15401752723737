import ApiService from "../api.service";
const main = "candidateDocument";

export default {
  getList({ search, status, params, regionId }) {
    return ApiService.post(
      `${main}/list?search=${search || ""}&statuses=${status ||
        ""}&regionId=${regionId || ""}`,
      params
    );
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id/${id}`);
  },
  //   changeStatusToSeen
  changeStatusToSeen(id) {
    return ApiService.get(`${main}/changeStatusToSeen/${id}`);
  },
  getCandidateId(id) {
    return ApiService.get(`${main}/get-by-candidateId/${id}`);
  },
  cancel(data) {
    return ApiService.post(`${main}/cancel`, data);
  },
  accept(data) {
    return ApiService.post(`${main}/accept`, data);
  },
  delete(id) {
    return ApiService.delete(`${main}/delete/${id}`);
  },
};
