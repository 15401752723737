<template>
  <div>
    <div
      class="
        intro-y
        w-full
        col-span-12
        flex flex-wrap
        justify-between
        sm:flex-nowrap
        items-center
        mt-2
      "
    >
      <div class="col-span-7 w-56">
        <CSelectNaive
          v-model:value="roleId"
          :options="roles"
          :label="`name`"
          :placeholder="$t('roles')"
          class="w-full"
        />
      </div>
      <div class="col-span-5">
        <button
          @click.prevent="addPermission"
          class="btn btn-primary shadow-md"
        >
          {{ $t("actions.save") }}
        </button>
      </div>
    </div>
    <app-loading
      v-if="roleId"
      :show="loading"
      class="overflow-x-auto mt-9 xl:overflow-x-scroll"
    >
      <div class="flex flex-col">
        <!-- <n-card class="mb-3" :title="'Permissions'">
          <n-checkbox-group v-if="list" v-model:value="permissionIds">
            @update:value="checking"
            <n-grid
              v-for="(per, i) in listFilter"
              :key="i"
              :y-gap="8"
              :cols="2"
            >
              <n-gi>
                <n-checkbox :value="per.id" :label="per.name" />
              </n-gi>
            </n-grid>
          </n-checkbox-group>
        </n-card> -->
        <n-checkbox-group v-if="list" v-model:value="permissionIds">
          <n-card
            v-for="(per, per_i) in list"
            :key="per_i"
            :title="per_i"
            class="mb-3"
          >
            <div class="flex flex-wrap -mx-1">
              <div class="mb-2 mx-1" v-for="(per_in, in_i) in per" :key="in_i">
                <n-card :title="in_i">
                  <n-grid v-for="(can, can_i) in per_in" :key="can_i" :cols="1">
                    <n-gi>
                      <n-checkbox :value="can.id" :label="can.name" />
                    </n-gi>
                  </n-grid>
                </n-card>
              </div>
            </div>
          </n-card>
        </n-checkbox-group>
      </div>
      <!-- <pre>
        {{ permissionIds }}
      </pre> -->
    </app-loading>
    <n-card v-else class="mt-6"> {{ t("selectRole") }} </n-card>
    <!-- EDIT -->
  </div>
</template>

<script setup >
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";

import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";

import RoleServise from "@/services/reference/role.service";
import PermissionServise from "@/services/reference/permission.service";

const { t } = useI18n();

let loading = ref(false);
let permissionIds = ref([]);

let bool = ref(true);

let roleId = ref("");
let roles = ref([]);
async function getRoles() {
  try {
    let res = await RoleServise.getList({
      params: {
        limit: 1000,
        page: 0,
      },
    });
    roles.value = res.data.list;
  } finally {
  }
}
watch(
  () => roleId.value,
  () => {
    permissionIds.value = [];
    if (roleId.value) {
      listByRoleId();
    }
  },
  { deep: true }
);

let rolePermission = ref([]);
async function listByRoleId() {
  try {
    let res = await PermissionServise.listByRoleId(roleId?.value?.id);
    rolePermission.value = res.data;
  } finally {
  }
}
watch(
  () => rolePermission.value,
  () => {
    if (rolePermission.value) {
      rolePermission.value.forEach((el) => {
        if (permissionIds.value.indexOf(el.id) === -1) {
          permissionIds.value.push(el.id);
        }
      });
    }
  },
  { deep: true }
);

let list = ref("");
async function getList() {
  try {
    loading.value = true;
    let res = await PermissionServise.getList();
    list.value = res.data;
  } finally {
    loading.value = false;
  }
}
let listFilter = computed(() => {
  if (list.value) {
    // return list.value.reference.References;
    return list.value.reference.References;
  }
});

let checking = (e) => {
  permissionIds.value = e;
};

let addPermission = async () => {
  try {
    let res = await PermissionServise.addPermission({
      roleId: roleId.value.id,
      permissionIds: permissionIds.value,
    });
    console.log("ADD", res);
  } finally {
    getList();
    listByRoleId();
  }
};

onMounted(() => {
  getRoles();
  getList();
});
</script>

<style>
</style>