import i18n from '../plugins/vue-i18n'
const { locale } = i18n.global
const Util = {
 
    paginate(index, page, limit) {
        return (index + 1) + (page - 1) * limit
    },
    localeKey(key = 'name') {
        return locale == "lt"
            ? key + 'Lt'
            : locale == "ru"
                ? key + 'Ru'
                : locale == "eng"
                    ? key + 'En'
                    : locale == "uz"
                        ? key + 'Uz'
                        : "";
    },
    enc(id) {
        let payload = "did$tAid$z"
        try {
            let params = window.btoa(payload)
            let idencoded = window.btoa(params + id)
            return idencoded
        } catch (error) {
            return null
        }
    },
    dec(args) {
        let payload = "did$tAid$z"
        try {
            let params = window.atob(args)
            let givenVal = window.btoa(payload)
            let arr = params.split(givenVal)
            return parseInt(arr[1])
        } catch (error) {
            return null
        }

    },
    replaceData(date) {
        return new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
    },
    DDMMYYYY(replacedData) {
        let month = new Date(
            null,
            replacedData.getMonth() + 1,
            null
        ).toLocaleDateString("en", { month: "2-digit" });
        let day = replacedData.getDate();
        let year = replacedData.getFullYear();
        let digitDay = day.toString().length === 1 ? "0" + day : day;
        return digitDay + "-" + month + "-" + year;
    },

    YYYYMMDD(replacedData) {
        let month = new Date(
            null,
            replacedData.getMonth() + 1,
            null
        ).toLocaleDateString("en", { month: "2-digit" });
        let day = replacedData.getDate();
        let year = replacedData.getFullYear();
        let digitDay = day.toString().length === 1 ? "0" + day : day;
        return year + "-" + month + "-" + digitDay;
    },
    toLatin(word) {
        var answer = "",
            a = {};
        a["ҳ"] = "h";
        a["Ҳ"] = "H";
        a["Ё"] = "YO";
        a["Й"] = "Y";
        a["Ц"] = "TS";
        a["У"] = "U";
        a["К"] = "K";
        a["Е"] = "E";
        a["Н"] = "N";
        a["Г"] = "G";
        a["Ш"] = "SH";
        a["З"] = "Z";
        a["Х"] = "X";
        a["Ъ"] = "ʼ";
        a["ё"] = "yo";
        a["й"] = "y";
        a["ц"] = "ts";
        a["у"] = "u";
        a["к"] = "k";
        a["е"] = "e";
        a["н"] = "n";
        a["г"] = "g";
        a["ш"] = "sh";
        a["з"] = "z";
        a["х"] = "x";
        a["ъ"] = "ʼ";
        a["Ф"] = "F";
        a["Ы"] = "I";
        a["В"] = "V";
        a["А"] = "A";
        a["П"] = "P";
        a["Р"] = "R";
        a["О"] = "O";
        a["Л"] = "L";
        a["Д"] = "D";
        a["Ж"] = "J";
        a["Э"] = "E";
        a["ф"] = "f";
        a["ы"] = "i";
        a["в"] = "v";
        a["а"] = "a";
        a["п"] = "p";
        a["р"] = "r";
        a["о"] = "o";
        a["л"] = "l";
        a["д"] = "d";
        a["ж"] = "j";
        a["э"] = "e";
        a["Я"] = "Ya";
        a["Ч"] = "CH";
        a["С"] = "S";
        a["М"] = "M";
        a["И"] = "I";
        a["Т"] = "T";
        a["Ь"] = "";
        a["Б"] = "B";
        a["Ю"] = "Yu";
        a["я"] = "ya";
        a["ч"] = "ch";
        a["с"] = "s";
        a["м"] = "m";
        a["и"] = "i";
        a["т"] = "t";
        a["ь"] = "";
        a["б"] = "b";
        a["ю"] = "yu";
        a["қ"] = "q";
        a["ў"] = "o‘";
        a["Қ"] = "Q";
        a["Ў"] = "O‘";
        a["ғ"] = "g‘";
        a["Ғ"] = "G‘";
        a["В"] = "V";
        a["в"] = "v";
        word = word.split(" ").map(el => {
            if (el.toUpperCase() == el) {
                return el?.replaceAll("Я", "YA").replaceAll("Ё", "YO")?.replaceAll("Ю", "YU") || el
            }
            return el;
        }).join(" ")
        for (let i in word) {
            if (word.hasOwnProperty(i)) {
                if (a[word[i]] === undefined) {
                    answer += word[i];
                } else {
                    answer += a[word[i]];
                }
            }
        }
        return answer
    },

    toCyrill(word) {
        var answer = "",
            a = {};
        a["h"] = "ҳ";
        a["H"] = "Ҳ";
        a["YO"] = "Ё";
        a["Y"] = "Й";
        a["TS"] = "Ц";
        a["U"] = "У";
        a["K"] = "К";
        a["E"] = "Е";
        a["N"] = "Н";
        a["G"] = "Г";
        a["SH"] = "Ш";
        a["Z"] = "З";
        a["X"] = "Х";
        a["yo"] = "ё";
        a["y"] = "й";
        a["ts"] = "ц";
        a["u"] = "у";
        a["k"] = "к";
        a["e"] = "е";
        a["n"] = "н";
        a["g"] = "г";
        a["sh"] = "ш";
        a["z"] = "з";
        a["x"] = "х";
        a["F"] = "Ф";
        a["V"] = "В";
        a["A"] = "А";
        a["P"] = "П";
        a["R"] = "Р";
        a["O"] = "О";
        a["L"] = "Л";
        a["D"] = "Д";
        a["J"] = "Ж";
        a["E"] = "Е";
        a["f"] = "ф";
        a["v"] = "в";
        a["a"] = "а";
        a["p"] = "п";
        a["r"] = "р";
        a["o"] = "о";
        a["l"] = "л";
        a["d"] = "д";
        a["j"] = "ж";
        a["e"] = "е";
        a["Ya"] = "Я";
        a["CH"] = "Ч";
        a["S"] = "С";
        a["M"] = "М";
        a["I"] = "И";
        a["T"] = "Т";
        a["B"] = "Б";
        a["Yu"] = "Ю";
        a["ya"] = "я";
        a["ch"] = "ч";
        a["s"] = "с";
        a["m"] = "м";
        a["i"] = "и";
        a["t"] = "т";
        a["b"] = "б";
        a["yu"] = "ю";
        a["q"] = "қ";
        a["Q"] = "Қ";
        a["o'"] = "ў";
        a["O'"] = "Ў";
        a["Oʻ"] = "Ў";
        a["o‘"] = "ў";
        a["oʻ"] = "ў";
        a["oʻ"] = "ў";
        a["O‘"] = "Ў";
        a["g'"] = "ғ";
        a["gʻ"] = "ғ";
        a["g`"] = "ғ";
        a["g‘"] = "ғ";
        a["G`"] = "Ғ";
        a["G‘"] = "Ғ";
        a["G'"] = "Ғ";
        a["V"] = "В";
        a["v"] = "в";
        a["’"] = "ъ"
        word = word.split(" ").map(el => {
            if (el.indexOf('e') == 0) {
                return el.replace("e", "э")
            }
            else if (el.indexOf('E') == 0) {
                return el.replace("E", "Э")
            }
            return el
        }).join(" ")

        for (let i = 0; i < word.length; i++) {
            if (i + 1 <= word.length) {
                if (word[i] + word[i + 1] == "Ye" || word[i] + word[i + 1] == "YE") {
                    answer += "Е";
                    i++;
                } else if (word[i] + word[i + 1] == "ye") {
                    answer += "е";
                    i++;
                } else if (word[i] + word[i + 1] == "Ya" || word[i] + word[i + 1] == "YA") {
                    answer += "Я";
                    i++;
                } else if (word[i] + word[i + 1] == "ya") {
                    answer += "я";
                    i++;
                } else if (
                    word[i] + word[i + 1] == "Yu" ||
                    word[i] + word[i + 1] == "YU"
                ) {
                    answer += "Ю";
                    i++;
                } else if (word[i] + word[i + 1] == "yu") {
                    answer += "ю";
                    i++;
                } else if (
                    word[i] + word[i + 1] == "Ch" ||
                    word[i] + word[i + 1] == "CH"
                ) {
                    answer += "Ч";
                    i++;
                } else if (word[i] + word[i + 1] == "ch") {
                    answer += "ч";
                    i++;
                } else if (word[i] + word[i + 1] == "sh") {
                    answer += "ш";
                    i++;
                } else if (
                    word[i] + word[i + 1] == "Sh" ||
                    word[i] + word[i + 1] == "SH"
                ) {
                    answer += "Ш";
                    i++;
                } else if (word[i] + word[i + 1] == "ts") {
                    answer += "ц";
                    i++;
                } else if (
                    word[i] + word[i + 1] == "Ts" ||
                    word[i] + word[i + 1] == "TS"
                ) {
                    answer += "Ц";
                    i++;
                } else if (
                    word[i] + word[i + 1] == "Yo" ||
                    word[i] + word[i + 1] == "YO"
                ) {

                    if (word[i + 2] != "'" && word[i + 2] != "ʼ" && word[i + 2] != "‘") {
                        answer += "Ё";
                        i++
                    } else {
                        if (a[word[i]] === undefined) {
                            answer += word[i];
                        } else {
                            answer += a[word[i]];
                        }
                    }


                } else if (word[i] + word[i + 1] == "yo") {
                    if (word[i + 2] != "'" && word[i + 2] != "ʼ" && word[i + 2] != "‘") {
                        answer += "ё";
                        i++;

                    } else {
                        if (a[word[i]] === undefined) {
                            answer += word[i];
                        } else {
                            answer += a[word[i]];
                        }
                    }

                } else if (word[i] + word[i + 1] == "a'") {
                    answer += "аъ";
                    i++;
                } else if (word[i] + word[i + 1] == "o'") {
                    answer += "ў";
                    i++;
                } else if (word[i] + word[i + 1] == "g'") {
                    answer += "ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "O'") {
                    answer += "Ў";
                    i++;
                } else if (word[i] + word[i + 1] == "G'") {
                    answer += "Ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "A'") {
                    answer += "Aъ";
                    i++;
                } else if (word[i] + word[i + 1] == "aʼ") {
                    answer += "аъ";
                    i++;
                } else if (word[i] + word[i + 1] == "oʼ") {
                    answer += "ў";
                    i++;
                } else if (word[i] + word[i + 1] == "g‘" || word[i] + word[i + 1] == "gʻ") {
                    answer += "ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "Oʼ" || word[i] + word[i + 1] == "Oʻ") {
                    answer += "Ў";
                    i++;
                } else if (word[i] + word[i + 1] == "Gʼ" || word[i] + word[i + 1] == "Gʻ") {
                    answer += "Ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "Aʼ") {
                    answer += "Aъ";
                    i++;
                } else if (word[i] + word[i + 1] == "a‘") {
                    answer += "аъ";
                    i++;
                } else if (word[i] + word[i + 1] == "o‘" || word[i] + word[i + 1] == "oʻ") {
                    answer += "ў";
                    i++;
                } else if (word[i] + word[i + 1] == "g‘" || word[i] + word[i + 1] == "gʻ") {
                    answer += "ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "O‘") {
                    answer += "Ў";
                    i++;
                } else if (word[i] + word[i + 1] == "G‘") {
                    answer += "Ғ";
                    i++;
                } else if (word[i] + word[i + 1] == "A‘") {
                    answer += "Aъ";
                    i++;
                } else {
                    if (a[word[i]] === undefined) {
                        answer += word[i];
                    } else {
                        answer += a[word[i]];
                    }
                }
            }
        }

        return answer
    },
}
export default Util

