<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div
          class="
            hidden
            xl:flex
            flex-col
            justify-items-start
            items-start
            min-h-screen
          "
        >
          <div class="my-auto text-center">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16 mx-auto"
              :src="require(`@/assets/images/main/logo.png`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              {{ $t("sideMenuTitle") }}
            </div>
            <div
              class="
                -intro-x
                mt-5
                text-lg text-white text-opacity-70
                dark:text-gray-500
              "
            >
              {{ $t("title") }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <n-form
            ref="loginForm"
            :model="form"
            :rules="rules"
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              {{ $t("actions.login") }}
            </h2>

            <div class="intro-x mt-8">
              <n-form-item :label="$t('field.login')" path="username">
                <n-input
                  required
                  type="text"
                  class="
                    intro-x
                    login__input
                    form-control
                    px-4
                    border-gray-300
                    block
                    w-full
                  "
                  v-model:value="form.username"
                  :placeholder="$t('field.login')"
                />
              </n-form-item>

              <n-form-item :label="$t('field.password')" path="password">
                <n-input
                  required
                  v-model:value="form.password"
                  type="password"
                  show-password-on="click"
                  class="
                    intro-x
                    login__input
                    form-control
                    px-4
                    border-gray-300
                    block
                    mt-4
                    w-full
                  "
                  :placeholder="$t('field.password')"
                />
              </n-form-item>
            </div>

            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                @click="login"
                type="submit"
                class="btn btn-primary py-3 px-4 w-full align-top"
              >
                {{ $t("actions.login") }}
              </button>
            </div>
          </n-form>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const form = ref({
  username: "",
  password: "",
});
let loginForm = ref(null);
const { t } = useI18n();
const store = useStore();
async function login() {
  validate();
}
onMounted(() => {
  cash("body").removeClass("main").removeClass("error-page").addClass("login");
});
let rules = {
  username: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  password: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};

function validate() {
  loginForm.value?.validate((errors) => {
    console.log(errors);
    if (!errors) {
      store.dispatch("auth/login", form.value);
    } else {
    }
  });
}
</script>
<style >
.login__input .n-input-wrapper {
  width: 100% !important;
}
</style>