import { TokenService } from "@/services/storage.service";

const state = () => {
  return {
    permissions: [],
  };
};

// getters
const getters = {
  permissions: (state) => state.permissions,
};

// actions
const actions = {
  async getPermissions({ commit }) {
    const permissions = await TokenService.getPermissions();
    commit("setPermissions", permissions);
  },
};

// mutations
const mutations = {
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
