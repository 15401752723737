import { ref } from "vue";
// import { useStorage } from "vuex";
import { useStore } from "@/store";

export default {
  can(per) {
    let permission = useStore().state.permission.permissions;
    return permission?.includes(per);
  },
};
