<template>
  <div>
    <n-tabs type="card" class="mt-8 page-tab" v-model:value="status">
      <n-tab-pane
        v-for="(item, index) in statusList"
        :key="index"
        :name="item.status"
        :tab="$t(item.name)"
      >
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div
            class="
              intro-y
              col-span-12
              flex flex-wrap
              sm:flex-nowrap
              items-center
              w-full
              mt-2
            "
          >
            <div
              class="w-full mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex items-center"
            >
              <div class="w-56 relative text-gray-700 dark:text-gray-300">
                <input
                  type="text"
                  class="form-control w-56 box pr-10 placeholder-theme-13"
                  placeholder="Qidiruv"
                  v-model="search"
                />
                <SearchIcon
                  class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
              <div v-if="roles.includes(role?.code)" class="flex-grow mx-4">
                <c-select-naive
                  class="w-full"
                  :label="$localeKey('name')"
                  :options="regions"
                  v-model:value="region"
                />
              </div>
              <div
                class="
                  hidden
                  flex-grow
                  text-center
                  md:block
                  mx-auto
                  text-gray-600
                "
              >
                {{ total }} dan {{ list.length }} ta ko'rsatilmoqda
              </div>
              <select v-model="limit" class="w-20 form-select box ml-4">
                <option v-for="item in limitOpt" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>

            <button
              v-if="status == 'ACCEPTED'"
              :disabled="!selectedItems.length"
              :class="{ 'opacity-40': !selectedItems.length }"
              @click="interviewConfirmShow = true"
              class="btn btn-primary shadow-md mr-2"
            >
              {{ $t("interview.call") }}
            </button>
          </div>
          <!-- BEGIN: Data List -->
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <app-loading :show="loading">
              <table class="table table-report -mt-2">
                <thead>
                  <tr>
                    <th class="w-16" v-if="status == 'ACCEPTED'">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="$_.isEqual(selectedItems, list)"
                        @change="
                          !$_.isEqual(selectedItems, list)
                            ? (selectedItems = [...list])
                            : (selectedItems = [])
                        "
                      />
                    </th>
                    <th class="w-4 text-center">#</th>
                    <th class="whitespace-nowrap">
                      {{ $t("field.fio") }}
                    </th>
                    <th class="text-center">{{ $t("field.sendDate") }}</th>
                    <th class="text-center">
                      {{ $t("field.address") }}
                    </th>
                    <th class="text-center">
                      {{ $t("lisenceType") }}
                    </th>
                    <th class="text-center whitespace-nowrap">
                      {{ $t("field.phone") }}
                    </th>
                    <th class="whitespace-nowrap text-center">
                      {{ $t("status.title") }}
                    </th>
                    <th class="text-center whitespace-nowrap">
                      {{ $t("actions.title") }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="list.length">
                  <tr
                    v-for="(item, index) in list"
                    :key="index"
                    class="intro-x"
                  >
                    <td class="w-16" v-if="status == 'ACCEPTED'">
                      <input
                        :value="item"
                        v-model="selectedItems"
                        class="form-check-input"
                        type="checkbox"
                      />
                    </td>
                    <td class="w-4 text-center">
                      {{ $paginate(index, page, limit) }}
                    </td>
                    <td
                      class="font-semibold cursor-pointer"
                      @click="
                        selectedItem = item;
                        getInfoCandidate(item);
                      "
                    >
                      {{ item.candidateFullName }}
                    </td>
                    <td class="text-center">{{ item.date }}</td>
                    <td class="text-center">{{ item.candidateAddress }}</td>
                    <td class="text-center">
                      <div v-for="(l, index) in item.licenseTypes" :key="index">
                        {{ index + 1 }}. {{ l[$localeKey()] }}
                      </div>
                    </td>
                    <td class="w-40">
                      {{ item.candidateMobilePhone }}
                    </td>
                    <td>
                      <div
                        class="flex items-center justify-center"
                        :class="{
                          'text-theme-9': item.status == 'SEND',
                          'text-theme-6': item.status == 'CANCELLED',
                          'text-theme-3': item.status == 'ACCEPTED',
                        }"
                      >
                        <CheckSquareIcon
                          v-if="item.status == 'SEND'"
                          class="w-4 h-4 mr-2"
                        />
                        <XSquareIcon
                          v-else-if="item.status == 'CANCELLED'"
                          class="w-4 h-4 mr-2"
                        />
                        <CheckSquareIcon v-else class="w-4 h-4 mr-2" />
                        {{ $t(`status.${item.status}`) }}
                      </div>
                    </td>
                    <td class="table-report__action w-56">
                      <div class="flex justify-center items-center">
                        <a
                          class="flex items-center ml-3 btn btn-primary"
                          href="javascript:;"
                          @click="
                            selectedItem = item;
                            getInfoCandidate(item);
                          "
                        >
                          <UserIcon class="w-5 h-5" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <not-found v-else colspan="9"></not-found>
              </table>
            </app-loading>
          </div>
          <!-- END: Data List -->
          <!-- BEGIN: Pagination -->
          <n-pagination
            v-model:page="page"
            :page-count="Math.ceil(total / limit)"
          />
          <!-- END: Pagination -->
        </div>
      </n-tab-pane>
    </n-tabs>
    <!-- cancel confirm -->
    <n-modal v-model:show="cancelShow">
      <n-card style="width: 600px">
        <template #header>
          <div>{{ $t("rejectionReason") }}</div>
        </template>
        <n-input
          v-model:value="cancelReason"
          type="textarea"
          placeholder="Sababi"
        />
        <template #action>
          <div class="flex items-center justify-end">
            <n-button @click="cancelShow = false" class="mr-5">
              Bekor qilish</n-button
            >
            <n-button type="error" @click="setCancelStatus">
              Rad qilish
            </n-button>
          </div>
        </template>
      </n-card>
    </n-modal>
    <!-- END: Delete Confirmation Modal -->
    <!-- create interview -->
    <n-modal
      v-model:show="interviewShow"
      preset="dialog"
      :mask-closable="false"
    >
      <template #header>
        <h1 class="">{{ $t("callCandidateToConversation") }}</h1>
      </template>
      <InterviewForm
        @close="interviewShow = false"
        @success="createInterview"
      />
    </n-modal>
    <!-- confirm selected -->
    <n-modal
      v-model:show="interviewConfirmShow"
      preset="dialog"
      :mask-closable="false"
      class="large-modal"
    >
      <template #header>
        <h1 class="">{{ $t("actions.confirm") }}</h1>
      </template>
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="w-16">
                <input class="form-check-input" type="checkbox" disabled />
              </th>
              <th class="whitespace-nowrap">{{ $t("field.fio") }}</th>
              <th class="whitespace-nowrap text-center">
                {{ $t("status.title") }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ $t("actions.title") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr
              v-for="(item, index) in selectedItems"
              :key="index"
              class="intro-x"
            >
              <td class="w-16">
                <input
                  :value="item"
                  v-model="selectedItems"
                  class="form-check-input"
                  type="checkbox"
                />
              </td>
              <td class="font-semibold">
                {{ item.candidateFullName }}
              </td>
              <td>
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-9': item.status == 'SEND',
                    'text-theme-6': item.status == 'CANCELLED',
                    'text-theme-3': item.status == 'ACCEPTED',
                  }"
                >
                  <CheckSquareIcon
                    v-if="item.status == 'SEND'"
                    class="w-4 h-4 mr-2"
                  />
                  <XSquareIcon
                    v-if="item.status == 'CANCELLED'"
                    class="w-4 h-4 mr-2"
                  />
                  <CheckSquareIcon v-else class="w-4 h-4 mr-2" />
                  {{ $t(`status.${item.status}`) }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a
                    class="flex items-center ml-3"
                    href="javascript:;"
                    @click="
                      sidebarShow = true;
                      selectedItem = item;
                    "
                  >
                    <EyeIcon class="w-4 h-4 m-1" />
                    {{ $t("actions.view") }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <not-found v-else colspan="6"></not-found>
        </table>
      </div>
      <template #action>
        <div class="flex justify-end">
          <button
            v-if="selectedItems.length"
            @click="interviewShow = true"
            class="btn btn-primary shadow-md"
          >
            {{ $t("actions.confirm") }}
          </button>
        </div>
      </template>
    </n-modal>
    <!-- Confirm -->
    <n-modal
      v-model:show="acceptShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('actions.confirm')"
      :content="$t('actions.confirm')"
      :positive-text="$t('actions.accept')"
      :negative-text="$t('actions.cancel')"
      @positive-click="setAcceptStatus"
      @negative-click="acceptShow = false"
    />
    <!-- Candidate info -->
    <!-- Candidate Info -->
    <n-modal
      v-model:show="sidebarShow"
      preset="dialog"
      class="extra-modal bg-theme-2"
    >
      <template #header>
        <div class="flex justify-end items-center w-full">
          <button
            v-if="status == 'SEND'"
            @click="acceptShow = true"
            class="btn bg-theme-10 text-sm text-white"
          >
            <CheckCircleIcon class="mr-1 w-5" />
            {{ $t("actions.accept") }}
          </button>
          <button
            v-if="status == 'SEND'"
            @click="cancelShow = true"
            class="btn btn-danger mx-5 text-sm"
          >
            <XIcon class="mr-1 w-5" />
            {{ $t("actions.reject") }}
          </button>
        </div>
      </template>
      <app-loading :show="loading" class="">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="relative flex items-center p-5">
              <div class="w-24 h-24 image-fit flex-shrink-0">
                <n-image
                  v-if="candidateInfo.uploadPath"
                  class="rounded-full wrap-can-image"
                  :src="`${$baseUrl}/${candidateInfo.uploadPath}`"
                />
                <img v-else class="rounded-full" src="" alt />
              </div>
              <div class="ml-4 mr-auto">
                <div class="font-medium text-base">
                  {{ candidateInfo.candidateFullName }}
                </div>
                <div class="text-theme-1 text-opacity-60">
                  {{ candidateInfo.candidateMobilePhone }}
                </div>
              </div>
            </div>
            <div
              class="box border-t border-gray-200 dark:border-dark-5"
              style="min-height: 500px"
            >
              <div class="px-4 pb-3 pt-5 ref-link">
                <button
                  class="flex items-center px-4 py-2 mt-1 one-line w-full"
                  :class="{
                    'bg-theme-1 text-white font-medium rounded-lg':
                      showPassportData,
                  }"
                  @click="showPassportData = true"
                >
                  <div class="flex-1 truncate">
                    {{ $t("passportData") }}
                  </div>
                </button>
                <button
                  v-for="(file, index) in candidateInfo.file"
                  :key="index"
                  class="flex items-center px-4 py-2 mt-1 one-line w-full"
                  :class="{
                    'bg-theme-1 text-white font-medium rounded-lg':
                      file.id == currentFile.id && !showPassportData,
                  }"
                  @click="
                    currentFile = { ...file };
                    showPassportData = false;
                  "
                >
                  <div class="flex-1 truncate">
                    {{ file[$localeKey("docTypeName")] }}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 lg:col-span-8 xl:col-span-9"
            v-if="currentFile.fileType && !showPassportData"
          >
            <div class="mt-4 mb-2 text-theme-1 text-xl">
              {{ currentFile[$localeKey("docTypeName")] }}
            </div>
            <file-view :file="currentFile" />
          </div>
          <div
            v-else
            class="intro-y col-span-12 lg:col-span-8 xl:col-span-9 mt-8"
          >
            <div
              class="
                bg-white
                rounded
                shadow-sm
                w-full
                p-4
                grid grid-cols-2
                gap-4
              "
            >
              <ul class="border rounded-md border-opacity-10 border-dark-3">
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.fio") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateFullName }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.passportSeria") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportNumber }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">
                    {{ $t("field.passportGivendate") }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportGivenDate }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">
                    {{ $t("field.passportEnddate") }}
                  </div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePassportEndDate }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.pinfl") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidatePNFL }}
                  </div>
                </li>
              </ul>
              <ul class="border rounded-md border-opacity-10 border-dark-3">
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.nationality") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateNationality }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.birthCountry") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateBirthAddress }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.address") }}</div>
                  <div class="col-span-2 text-right">
                    {{ candidateInfo.candidateAddress }}
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.phone") }}</div>
                  <div class="col-span-2 text-right">
                    <a
                      class="text-theme-4 text-opacity-75"
                      :href="`tel:${candidateInfo.candidateMobilePhone}`"
                    >
                      {{ candidateInfo.candidateMobilePhone }}
                    </a>
                  </div>
                </li>
                <li
                  class="
                    p-3
                    border-b border-dark-5 border-opacity-10
                    grid grid-cols-3
                    gap-x-2
                  "
                >
                  <div class="col-span-1">{{ $t("field.email") }}</div>
                  <div class="col-span-2 text-right">
                    <a
                      class="text-theme-4 text-opacity-75"
                      :href="`tel:${candidateInfo.candidateEmail}`"
                    >
                      {{ candidateInfo.candidateEmail }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </app-loading>
    </n-modal>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import CandidateService from "@/services/candidate/Candidate.service";
import InterviewService from "@/services/interview.service";
import RegionsService from "@/services/reference/region.service";
import { TokenService } from "@/services/storage.service";
import FileView from "@/components/main/file-view.vue";
import InterviewForm from "./components/InterviewForm.vue";
import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";

export default defineComponent({
  components: {
    FileView,
    InterviewForm,
    CSelectNaive,
  },
  setup() {
    const { t } = useI18n();
    const message = useMessage();
    const loadingBar = useLoadingBar();
    const router = useRouter();
    const page = ref(1);
    const cancelShow = ref(false);
    let sidebarShow = ref(false);
    const interviewShow = ref(false);
    const interviewConfirmShow = ref(false);
    const form = ref({
      date: Date.now(),
      phone: "",
    });
    let list = ref([]);
    let regions = ref([]);
    let region = ref("");
    let total = ref(0);
    let loading = ref(false);
    let limitOpt = [10, 20, 50, 100];
    let limit = ref(20);
    let search = ref("");
    let status = ref("SEND");
    let role = ref("");
    let roles = ref(["ROLE_ADMIN", "ROLE_MAIN_ADMIN"]);

    let statuss = ref(["SEND", "SEEN"]);
    watch(status, (val) => {
      if (val == "SEND") statuss.value = ["SEND", "SEEN"];
      else statuss.value = val;
    });

    let paramsComp = computed(() => {
      return {
        search: search.value,
        status: statuss.value,
        regionId: region.value?.id || "",
        params: {
          page: page.value - 1,
          limit: limit.value,
        },
      };
    });
    async function getList() {
      let payload = { ...paramsComp.value };
      payload.regionId = [payload.regionId];
      try {
        loading.value = true;
        let res = await CandidateService.getList(payload);
        list.value = res.data.list;
        total.value = res.data.total;
      } catch {
        list.value = [];
        total.value = 0;
      } finally {
        loading.value = false;
      }
    }
    async function getRegions() {
      let res = await RegionsService.getList();
      regions.value = res.data?.[0]?.children;
    }
    let statusList = ref([
      {
        status: "SEND",
        name: "arizaSent",
      },
      {
        status: "ACCEPTED",
        name: "arizaAccepted",
      },
      {
        status: "CANCELLED",
        name: "arizaCancelled",
      },
    ]);
    let selectedItem = ref({});
    let cancelReason = ref("");
    onMounted(() => {
      role.value = TokenService.getUserRoles();
      getList(paramsComp.value);
      getRegions();
    });
    watch(paramsComp, (val) => {
      getList();
    });
    async function setCancelStatus() {
      try {
        loadingBar.start();
        await CandidateService.cancel({
          comment: cancelReason.value,
          id: selectedItem.value.id,
        });
        message.info(t("success.saved"));
        cancelShow.value = false;
        cancelReason.value = "";
        sidebarShow.value = false;
        await getList();
      } finally {
        loadingBar.finish();
      }
    }
    // accept
    let acceptShow = ref(false);
    async function setAcceptStatus() {
      try {
        loadingBar.start();
        await CandidateService.accept({
          id: selectedItem.value.id,
        });
        acceptShow.value = false;
        sidebarShow.value = false;
        message.success(t("success.saved"));
        await getList();
      } finally {
        loadingBar.finish();
      }
    }
    // Select and send
    let selectedItems = ref([]);
    async function createInterview(e) {
      let payload = {
        ...e,
        candidateIds: selectedItems.value.map((el) => el.id),
      };
      try {
        loadingBar.start();
        await InterviewService.create(payload);
        message.success(t("success.saved"));
        router.push("/interview");
      } finally {
        loadingBar.finish();
      }
    }
    // Show candidate files
    let candidateInfo = ref({});
    let currentFile = ref({});
    let showPassportData = ref(true);
    async function getInfoCandidate(item) {
      sidebarShow.value = true;
      showPassportData.value = true;
      try {
        loading.value = true;
        let res = await CandidateService.changeStatusToSeen(item.candidateId);
        candidateInfo.value = res.data[0] || {};
        currentFile.value = candidateInfo.value.file?.[0] || {};
      } finally {
        loading.value = false;
      }
    }
    return {
      candidateInfo,
      currentFile,
      getInfoCandidate,
      showPassportData,
      interviewShow,
      page,
      cancelShow,
      form,
      list,
      total,
      limit,
      limitOpt,
      loading,
      search,
      sidebarShow,
      interviewConfirmShow,
      statusList,
      status,
      cancelReason,
      selectedItem,
      setCancelStatus,
      acceptShow,
      setAcceptStatus,
      selectedItems,
      createInterview,
      regions,
      region,
      roles,
      role,
    };
  },
});
</script>
<style lang="scss">
.wrap-can-image {
  img {
    object-fit: contain !important;
  }
}
.page-tab .n-tabs-tab__label {
  @apply text-xl font-roboto;
}
.drawer-tab {
  .n-tabs-tab {
    span {
      max-width: 250px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
