import ApiService from "../api.service";
const main = "directory-question";

export default {
  getList({ params, search, questionTypeId, licenseTypeId }) {
    return ApiService.post(
      `${main}/list?search=${search || ""}&licenseTypeId=${licenseTypeId ||
        ""}&questionTypeId=${questionTypeId || ""}`,
      params
    );
  },
  // licenseTypes
  licenseTypes({ params }) {
    return ApiService.post(`getInfo/list?search=`, params);
  },
  // POST http://localhost:8282/api/v.1/directory-question/change
  change({ id, active }) {
    console.log("change", id, active);
    return ApiService.post(
      `${main}/change?id=${id || ""}&active=${active}`,
      {}
    );
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id/${id}`);
  },
  create(data) {
    return ApiService.post(`${main}/create`, data);
  },
  update(data) {
    return ApiService.post(`${main}/update`, data);
  },
  delete(id) {
    return ApiService.delete(`${main}/delete/${id}`);
  },
};
