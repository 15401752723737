<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    style="max-width: 700px"
  >
    <n-form-item :label="$t('name.title')" path="name">
      <n-input  type="text" v-model:value="formValue.name" placeholder="" />
    </n-form-item>
    <n-form-item :label="$t('field.code')" path="code">
      <n-input  type="text" v-model:value="formValue.code" placeholder="" />
    </n-form-item>
    <n-form-item class="flex items-center justify-end mt-4">
      <button @click="$emit('close')" class="btn btn-secondary">
        {{ $t('actions.cancel') }}
      </button>
      <button @click="validate" class="btn btn-primary ml-4">{{ $t("actions.save") }}</button>
    </n-form-item>
  </n-form>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
});
let formValue = ref({
 name : "",
 code : ''
});
let rules = {
  name: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  code: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};

onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
  }
});

function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      emits("success", formValue.value);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style>
</style>