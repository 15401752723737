<template>
  <div class="flex flex-wrap">
    <div class="w-1/2 pr-2">
      <v-select
        v-model="selectedAssessors"
        :placeholder="$t('field.fio')"
        :options="comissionsOptions"
        filterable
        class="w-full"
        :label="$localeKey()"
        multiple
        id="multiple-select"
      >
        <template #option="option">
          <div class="text-xl">
            {{ option.lastName }} {{ option.firstName }} {{ option.parentName }}
          </div>
          <div>{{ option[$localeKey("positionName")] }}</div>
        </template>
        <template #selected-option="option">
          <div class="flex flex-col w-full">
            <div class="text-xl">
              {{ option.lastName }} {{ option.firstName }}
              {{ option.parentName }}
            </div>
            <div>{{ option[$localeKey("positionName")] }}</div>
          </div>
        </template>
      </v-select>
    </div>
    <div class="w-1/2">
      <div
        class="w-full p-2 border rounded-md mb-2"
        style="min-height: 80px"
        v-for="(item, index) in selectedAssessorsOptions"
        :key="index"
      >
        <div class="text-xl text-theme-1">
          {{ item.lastName }} {{ item.firstName }} {{ item.parentName }}
        </div>
        <div>{{ item[$localeKey("positionName")] }}</div>
        <!-- :selectable="(option) => limits[option.id] != 4" -->
        <v-select
          v-model="item.typeId"
          placeholder=""
          :options="commissionTypes"
          filterable
          :reduce="(el) => el"
          class="w-full"
          :label="$localeKey()"
          :selectable="
            (option) => {
              if (limits[option.id]) {
                if (limits[option.id] < option.limits) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          "
        >
        </v-select>
        <!-- <pre>
          {{ item.typeId?.id }} : {{ item.typeId?.limits }}
        </pre> -->
      </div>
      <!-- <div class="flex">
        <pre>
        {{ limits }}
        </pre>
        <pre>
        {{ commissionTypes }}
        </pre>
      </div> -->
    </div>
  </div>

  <div class="flex items-center justify-end mt-4 w-full">
    <button @click="$emit('close')" class="btn btn-secondary">
      {{ $t("actions.cancel") }}
    </button>
    <button @click="validate" class="btn btn-primary ml-4">
      {{ $t("actions.save") }}
    </button>
  </div>
</template>

<script setup>
import InterviewService from "@/services/interview.service";
import ComTypeService from "@/services/reference/commission-type.service";
import ComissionsService from "@/services/reference/comission.service";

import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";

import { ref, watch, onMounted, computed } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
});

// Options
let payload = ref({
  page: 0,
  limit: 50,
});
let assessors = ref([]);
let selectedAssessors = ref([]);
let commissionTypes = ref([]);
let comissions = ref([]);
let limits = ref({});
async function getAssessors(data) {
  let res = await InterviewService.listAssessors(data);
  assessors.value = res.data.list;
}
async function getComTypes(data) {
  let res = await ComTypeService.getList(data);
  commissionTypes.value = res.data.list;
}
async function getComissions(data) {
  let res = await ComissionsService.getListDto(data);
  comissions.value = res.data.list;
}

let comissionsOptions = computed(() => {
  return comissions.value.map((el) => {
    return {
      ...el.userDtos,
      typeId: el.directoryInterviewAppraisersDtos,
    };
  });
});

let selectedAssessorsOptions = computed(() => {
  return selectedAssessors.value;
});

watch(
  () => comissionsOptions.value,
  (val) => {
    if (val.length > 0) {
      selectedAssessors.value = val;
    }
  },
  { deep: true }
);

watch(
  () => selectedAssessors.value,
  (val) => {
    if (val) {
      limits.value = {};
      val.forEach((el) => {
        if (el.typeId && el.typeId.id) {
          limits.value[el.typeId.id] = limits.value[el.typeId.id]
            ? limits.value[el.typeId.id] + 1
            : 1;
        }
      });
    }
  },
  { deep: true }
);

// let limits = computed(() => {
//   let limits = {};
//   if (selectedAssessors.value) {
//     selectedAssessors.value.forEach((el) => {
//       if (el.typeId) {
//         limits[el.typeId.id] = limits[el.typeId.id]
//           ? limits[el.typeId.id] + 1
//           : 1;
//       }
//     });
//   }
//   return limits;
// });

onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
  }

  getAssessors({ params: payload.value });
  getComTypes({ params: payload.value });
  getComissions({ params: payload.value });
});
function validate() {
  if (
    selectedAssessors.value.length == 0 ||
    selectedAssessors.value.some((el) => !el.typeId)
  ) {
    message.error(t("error.invalid"));
  } else {
    let payload = selectedAssessors.value.map((el) => {
      return {
        assessorId: el.id,
        appraiserId: el.typeId?.id || "",
      };
    });
    emits("success", payload);
  }
}
</script>

<style lang="scss">
#multiple-select {
  .vs__dropdown-toggle {
    padding: 5px;
  }
  .vs__selected {
    @apply w-full bg-theme-3 text-white rounded p-2;
    min-height: 85px;
    path {
      fill: #ffff;
    }
  }
  .vs__actions {
    display: none;
  }
}
</style>