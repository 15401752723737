<template>
  <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
    <router-link to="/">{{ $t("mainPage") }}</router-link>
    <template v-for="(item, index) in routesPath" :key="index">
      <template v-if="item.title && item.path !== '/'">
        <ChevronRightIcon class="breadcrumb__icon" />
        <router-link
          :class="{ disabled: item.disabled }"
          :to="item.path"
          class="breadcrumb--active"
          >{{ $t(`${item.title}`) }}</router-link
        >
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const routesPath = computed(() => {
  return router.currentRoute.value.matched.map((r) => {
    return {
      path: r.path,
      title: r.meta.title,
    };
  });
});

onMounted(() => {});
</script>

<style>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>