export const locale = {
  lang: {
    ru: "Русский",
    lt: "O‘zbek (Lotin)",
    uz: "Ўзбек (кирилл)",
    eng: "English",
  },
  menu: {
    home: "Домашняя страница",
    requests: "Заявки",
    interview: "Собеседования",
    reference: "Справочник",
    users: "Пользователи",
    sheet: "Листы",
    comissions: "Комиссии",
  },
  name: {
    title: "Nomi",
    lt: "Nomi(lotin)",
    uz: "Номи(кирилл)",
    ru: "Название(русский)",
    en: "Name(english)",
  },
  question: {
    lt: "Savol(lotin)",
    uz: "Савол(кирилл)",
    ru: "Вопрос(русский)",
    en: "Question(english)",
  },
  answer: {
    lt: "Javob(lotin)",
    uz: "Жавоб(кирилл)",
    ru: "Ответь(русский)",
    en: "Answer(english)",
  },
  actions: {
    title: "Действие",
    login: "Войти",
    cancel: "Отмена",
    saveAndSend: "Сохранить и отправить",
    save: "Сохранить",
    confirm: "Подтвердить",
    view: "Просмотр",
    download: "Скачать",
    accept: "Принять",
    reject: "Отклонить",
    add: "Добавить",
    back: "Назад",
    delete: "Удалить",
    finish: "Завершить",
    print: "Печать",
    close: "Закрыть",
  },
  newsHedline: {
    lt: "Yangilik sarlavhasi (lotin)",
    uz: "Янгилик сарлавҳаси (кирил)",
    ru: "Новостная статья (русский)",
    en: "News headline (english)",
  },
  newsPost: {
    lt: "Yangilik matni (lotin)",
    uz: "Янгилик матни (кирил)",
    ru: "Новостная статья (русский)",
    en: "News text (english)",
  },
  all: "Все",
  user: "Пользователь",
  counts: "Количество",
  numberOfAttachments: "Количество вложений",
  permissions: "Права доступа",
  roles: "Роли",
  selectRole: "Выберите роль",
};
