import ApiService from "../api.service";
const main = "user";

export default {
  getList({ params, search, contragentId }) {
    return ApiService.post(
      `${main}/list-assessors?search=${search ||
        ""}&contragentId=${contragentId || ""}`,
      params
    );
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id/${id}`);
  },
  create(data) {
    return ApiService.post(`${main}/create`, data);
  },
  update(data) {
    return ApiService.post(`${main}/update`, {
      firstName: data.firstName,
      lastName: data.lastName,
      parentName: data.parentName,
      username: data.username,
      pnfl: data.pnfl,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      contragentId: data.contragentId,
      positionId: data.positionId,
      inn: data.inn,
      roleId: data.roleId,
      phone: data.phone,
      id: data.id,
    });
  },
  change({ userId, status }) {
    return ApiService.post(
      `${main}/change?status=${status || ""}&userId=${userId || ""}`
    );
  },
  // http://localhost:8282/api/v.1/user/changePassword?newPassword=123&password=3213
  changePassword({ newPassword, password }) {
    return ApiService.post(
      `${main}/changePassword?newPassword=${newPassword ||
        ""}&password=${password || ""}`
    );
  },
  delete(id) {
    return ApiService.get(`${main}/delete/${id}`);
  },
};
