export const locale = {
  lang: {
    ru: "Русский",
    lt: "O‘zbek (Lotin)",
    uz: "Ўзбек (кирилл)",
    eng: "English",
  },
  menu: {
    home: "Home page",
    requests: "Requests",
    interview: "Interviews",
    reference: "Reference",
    users: "Users",
    sheet: "Sheets",
    comissions: "Commissions",
  },
  name: {
    title: "Nomi",
    lt: "Nomi(lotin)",
    uz: "Номи(кирилл)",
    ru: "Название(русский)",
    en: "Name(english)",
  },
  question: {
    lt: "Savol(lotin)",
    uz: "Савол(кирилл)",
    ru: "Вопрос(русский)",
    en: "Question(english)",
  },
  answer: {
    lt: "Javob(lotin)",
    uz: "Жавоб(кирилл)",
    ru: "Ответь(русский)",
    en: "Answer(english)",
  },
  arizaSent: "Ariza topshirgan advokatlar",
  status: {
    active: "active",
    inactive: "inactive",
  },
  actions: {
    title: "Action",
    login: "Login",
    cancel: "Cancel",
    saveAndSend: "Save and send",
    save: "Save",
    confirm: "Confirm",
    view: "View",
    download: "Download",
    accept: "Accept",
    reject: "Reject",
    add: "Add",
    back: "Back",
    delete: "Delete",
    finish: "Finish",
    print: "Print",
    close: "Close",
  },
  error: {
    empty: "This field cannot be empty!",
    invalid: "The information is incomplete!",
  },
  interview: {
    title: "interviews",
  },
  success: {
    saved: "Successfully saved",
  },
  newsHedline: {
    lt: "Yangilik sarlavhasi (lotin)",
    uz: "Янгилик сарлавҳаси (кирил)",
    ru: "Новостная статья (русский)",
    en: "News headline (english)",
  },
  newsPost: {
    lt: "Yangilik matni (lotin)",
    uz: "Янгилик матни (кирил)",
    ru: "Новостная статья (русский)",
    en: "News text (english)",
  },
  all: "All",
  counts: "Counts",
  numberOfAttachments: "Number of attachments",
  permissions: "Permissions",
  roles: "Roles",
  selectRole: "Select role",
  user: "User",
};
