<template>
  <div>
    <n-space class="w-full" vertical>
      <n-select
        :status="v ? 'error' : 'success'"
        :placeholder="placeholder"
        v-model:value="inputValue"
        :options="renderOptions"
        clearable
      />
    </n-space>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    options: {
      default: () => [],
    },
    label: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    res: {
      default: "",
    },
    v: {
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        if (this.value) {
          return this.value;
        } else {
          return null;
        }
      },
      set(val) {
        // this.$emit("update:value", val);
        if (this.res) {
          this.$emit("update:" + val[this.res]);
        } else {
          this.$emit("update:value", val);
        }
      },
    },
    renderOptions() {
      if (this.options.length > 0) {
        return this.options.map((item) => {
          return {
            label: item[this.label] || item,
            value: item,
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
</style>