import Tippy from "./tippy/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default app => {
  app.component("Tippy", Tippy);
  app.component("LoadingIcon", LoadingIcon);
  app.component("v-select", vSelect);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
