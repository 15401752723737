export const locale = {
  lang: {
    ru: "Русский",
    lt: "O‘zbek (Lotin)",
    uz: "Ўзбек (кирилл)",
    eng: "English",
  },
  menu: {
    home: "Asosiy sahifa",
    requests: "Arizalar",
    interview: "Suhbatlar",
    reference: "Ma'lumotnoma",
    users: "Foydalanuvchilar",
    failedList: "Yiqilganlar ro'yxati",
    sheet: "Baholar",
    comissions: "Komissiya azolari",
  },
  name: {
    title: "Nomi",
    lt: "Nomi(lotin)",
    uz: "Номи(кирилл)",
    ru: "Название(русский)",
    en: "Name(english)",
  },
  question: {
    lt: "Savol(lotin)",
    uz: "Савол(кирилл)",
    ru: "Вопрос(русский)",
    en: "Question(english)",
  },
  answer: {
    lt: "Javob(lotin)",
    uz: "Жавоб(кирилл)",
    ru: "Ответь(русский)",
    en: "Answer(english)",
  },
  status: {
    title: "Holati",
    active: "faol",
    inactive: "faol emas",
    SEND: "Ko'rib chiqilmoqda",
    CANCELLED: "Rad qilindi",
    ACCEPTED: "Qabul qilindi",
    FAILED: "Yiqilgan",
    ATTACHED_CANDIDATE: "Yaratildi",
    ATTACHED_ASSESSORS: "Komissiya biriktirildi",
    MEETING_PROCESS: "Suhbat boshlandi",
    CLOSED: "Suhbat yopildi",
    FINISHED: "Suhbat tugatildi",
    PROTOCOL: "Protokol tuzildi",
    PROTOCOL_SIGN_PROCESS: "Protokol imzolashda",
    PROTOCOL_SIGNED: "Protokol imzolandi",
    SEEN: "Ko'rildi",
  },
  actions: {
    title: "Amallar",
    login: "Tizimga kirish",
    cancel: "Bekor qilish",
    saveAndSend: "saqlash va jo'natish",
    save: "Saqlash",
    confirm: "Tasdiqlash",
    view: "Ko'rish",
    download: "Yuklab olish",
    uploadFile: "Fayl yuklash",
    accept: "Qabul qilish",
    reject: "Rad qilish",
    add: "Qo'shish",
    back: "Orqaga",
    delete: "O'chirish",
    finish: "Tugatish",
    print: "Chop qilish",
    close: "Yopish",
    imzolash: "Imzolash",
    chooseKey: "Kalitni tanlang",
    viewPdf: "Pdf ko'rish",
    edit: "Tahrirlash",
    qaytarish: "Qaytarish",
    sendProtocol: "Hujjatni yuborish",
  },
  error: {
    empty: "Bu maydon bo'sh bo'lishi mumkin emas!",
    invalid: "Ma'lumotlar to'liq to'ldirilmagan!",
    field: "Ma'lumot xato to'ldirilgan",
    change: "Ma'lumotni o'zgartirishda xatolik!",
  },
  interview: {
    title: "Suhbatlar",
    addCommission: "Komissiya a'zolari biriktirish",
    commission: "Komissiya a'zolari",
    candidate: "Nomzodlar ro'yhati",
    start: "Suhbatni boshlash",
    finish: "Suhbatni yopish",
    sureConfirm:
      "Eslatma: Suhbat boshlangandan so'ng komissiya a'zolarini o'zgartira olmasiz!",
    started: "Suhbat boshlandi!",
    call: "Suhbatga chaqirish",
    sheet: "Baholar",
    protocol: "Suhbat Protokoli",
    allowResubmission: "Qayta ariza yuborishga ruhsat berish"
  },
  success: {
    saved: "Muvaffaqiyatli saqlandi",
    deleted: "Muvaffaqiyatli o'chirildi",
    docSend: "Hujjat yuborildi",
    change: "Muvaffaqiyatli o'zgartirildi",
  },
  warning: {
    notFound: "Ma'lumot topilmadi",
  },
  field: {
    address: "Manzili",
    phone: "Telefon raqami",
    phoneCadr: "Kotibnig Telefon raqami",
    fio: "F.I.O",
    text: "Matn",
    time: "Vaqti",
    date: "Sanasi",
    region: "Hudud",
    firstName: "Ismi",
    lastName: "Familiyasi",
    parentName: "Otasining ismi",
    login: "Foydalanuvchi nomi",
    password: "Parol",
    passwordConfirm: "Parolni tasdiqlang",
    changePassword: "Parolni o'zgartirish",
    oldPassword: "Eski parol",
    newPassword: "Yangi parol",
    passwordConfirm: "Parolni tasdiqlang",
    pinfl: "PINFL",
    inn: "STIR",
    contragent: "Tashkilot nomi",
    staffPosition: "Lavozimi",
    role: "Roli",
    code: "Kodi",
    comType: "Komissiya turi",
    post: "Yangiliklar",
    passportSeria: "Pasport seriya va raqami",
    passportGivendate: "Pasport berilgan sana",
    passportEnddate: "Pasport amal qilish muddati",
    nationality: "Millati",
    birthday: "Tug'ilgan sanasi",
    birthCountry: "Tug'ilgan davlati",
    email: "Elektron pochta manzili",
    sendDate: "Hujjat yuborilgan vaqti",
  },
  requestDate: "SUhbatga kamida 5 kun oldin chaqirish kerak",
  counts: "Miqdori",
  user: "Foydalanuvchi",
  selectRole: "Rollni tanlang",
  roles: "Rollar",
  numberOfAttachments: "Biriktirishlar soni",
  sureDelete: "Rostan ham o'chirmoqchimisiz?",
  arizaSent: "Ariza topshirgan advokatlar",
  arizaCancelled: "Rad qilingan",
  arizaAccepted: "Qabul qilingan",
  mainPage: "Asosiy sahifa",
  sideMenuTitle: "O'zbekiston Respublikasi Adliya Vazirligi",
  title: "Advokatlar uchun litsenziya berish tizimi",
  lawyers: "Advokatlar",
  search: "Qidiruv",
  dan: "dan",
  showingOf: "ta ko'rsatilmoqda",
  callToConversation: "Suhbatga chaqirish",
  image: "Rasmi",
  lawyer: "Advokat",
  lawyers: "Advokatlar",
  address: "Manzili",
  phone: "Telefon raqami",
  rejectionReason: "Rad qilish sababi",
  reject: "Rad qilish",
  reason: "Sababi",
  callCandidateToConversation: "Nomzodlarni suhbatga chaqirish",
  dateOfConv: "Suhbat sanasi:",
  text: "Matn",
  archiveConv: "Arxiv Suhbatlar",
  interviewtable: "Suhbat",
  count: "Soni",
  references: "Ma'lumotlar",
  requiredDocs: "Talab etiladigan hujjatlar",
  lisenceType: "Litsenziya turi",
  permissions: "Ruxsatlar",
  questionType: "Savol turi",
  questionTypeCount: "Huquq tarmoqlari",
  questions: "Savollar",
  newsHedline: {
    lt: "Yangilik sarlavhasi (lotin)",
    uz: "Янгилик сарлавҳаси (кирил)",
    ru: "Новостная статья (русский)",
    en: "News headline (english)",
  },
  newsPost: {
    lt: "Yangilik matni (lotin)",
    uz: "Янгилик матни (кирил)",
    ru: "Новостная статья (русский)",
    en: "News text (english)",
  },
  passportData: "Passport ma'lumotlari",
  all: "Barcha",
  cancelReason: "Rad qilinishi sababi",
  pointSheet: "Baholash varaqasi",
  sendCommision: "Komissiya a'zolariga jo'natish",
  interviewReason: {
    t1: "Suhbat bosqichiga o'tdingiz. Suhbatdan o'tish uchun",
    t2: "da __________________ manzilga yetib kelishingiz so'raladi. ",
    t3: "Ma'lumot uchun telefon",
  },
};
