export const locale = {
  lang: {
    ru: "Русский",
    lt: "O‘zbek (Lotin)",
    uz: "Ўзбек (кирилл)",
    eng: "English",
  },
  name: {
    title: "Nomi",
    lt: "Nomi(lotin)",
    uz: "Номи(кирилл)",
    ru: "Название(русский)",
    en: "Name(english)",
  },
  question: {
    lt: "Savol(lotin)",
    uz: "Савол(кирилл)",
    ru: "Вопрос(русский)",
    en: "Question(english)",
  },
  answer: {
    lt: "Javob(lotin)",
    uz: "Жавоб(кирилл)",
    ru: "Ответь(русский)",
    en: "Answer(english)",
  },
  newsHedline: {
    lt: "Yangilik sarlavhasi (lotin)",
    uz: "Янгилик сарлавҳаси (кирил)",
    ru: "Новостная статья (русский)",
    en: "News headline (english)",
  },
  newsPost: {
    lt: "Yangilik matni (lotin)",
    uz: "Янгилик матни (кирил)",
    ru: "Новостная статья (русский)",
    en: "News text (english)",
  },
};
