<template>
  <n-form ref="formRef" :label-width="80" :model="formValue" :rules="rules" :size="size" style="max-width: 700px">
    <n-form-item :label="$t('name.uz')" path="nameUz">
      <n-input :type="inputType" v-model:value="formValue.nameUz" placeholder="" />
    </n-form-item>
    <n-form-item :label="$t('name.lt')" path="nameLt">
      <n-input :type="inputType" v-model:value="formValue.nameLt" placeholder="" />
    </n-form-item>
    <n-form-item :label="$t('name.en')" path="nameEn">
      <n-input :type="inputType" v-model:value="formValue.nameEn" placeholder="" />
    </n-form-item>
    <n-form-item :label="$t('name.ru')" path="nameRu">
      <n-input :type="inputType" v-model:value="formValue.nameRu" placeholder="" />
    </n-form-item>
    <n-form-item :label="$t('lisenceType')" path="licenseTypeId">
      <!-- :reduce="(el) => el.id" -->
      <v-select v-model="formValue.licenseTypeId" placeholder="" :options="licenseTypes" filterable class="w-full"
        :reduce="(el) => el" :label="$localeKey('name')" />
    </n-form-item>
    <n-form-item :label="$t('questionTypeCount')" path="questionTypeCountId">
      <!-- :reduce="(el) => el.id" -->
      <v-select v-model="formValue.questionTypeCountId" placeholder="" :options="questionTypeCountList" filterable
        class="w-full" :reduce="(el) => el.id" :label="$localeKey('name')" />
    </n-form-item>
    <n-form-item class="flex items-center justify-end mt-4">
      <button @click="$emit('close')" class="btn btn-secondary">
        {{ $t("actions.cancel") }}
      </button>
      <button @click="validate" class="btn btn-primary ml-4">
        {{ $t("actions.save") }}
      </button>
    </n-form-item>
  </n-form>
</template>

<script setup>
import QuestionTypeCountService from "@/services/reference/question-type-count.service";
import LicenseTypeService from "@/services/reference/license-type.service";

import { ref, watch, onMounted } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => { },
  },
  mode: {
    default: "create",
  },
  inputType: {
    default: "textarea",
  },
});
let formValue = ref({
  nameUz: "",
  nameLt: "",
  nameEn: "",
  nameRu: "",
  licenseTypeId: null,
  questionTypeCountId: null,
});
let rules = {
  nameUz: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameLt: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameEn: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameRu: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  licenseTypeId: {
    type: "object",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  questionTypeCountId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};

onMounted(() => {
  if (props.mode == "update") {
    for (const key in formValue.value) {
      if (Object.hasOwnProperty.call(formValue.value, key)) {
        formValue.value[key] = props.item[key];
      }
      formValue.value.id = props.item.id;
      formValue.value.questionTypeCountId = props.item.typeCountId;
      // formValue.value.id = props.item.id;
    }
    // if (props.item) {
    //   props.item.licenseTypeId
    //   console.log("props.item.licenseTypes", licenseTypes.value);
    // }
  }
});
let payload = ref({
  page: 0,
  limit: 50,
});
let licenseTypes = ref([]);

async function getLicenseTypes(data) {
  let res = await LicenseTypeService.getList({ params: data });
  licenseTypes.value = res.data.list;
}

let questionTypeCountList = ref([]);

async function getQuestionTypeCountList(data) {
  let res = await QuestionTypeCountService.getList({ params: data });
  questionTypeCountList.value = res.data.list;
}
// watch to licenseTypes
watch(
  () => licenseTypes.value,
  (val) => {
    if (val) {
      formValue.value.licenseTypeId = val.find(
        (el) => el.id == formValue.value.licenseTypeId
      );
    }
    // console.log("formValue.value.licenseTypeId", formValue.value.licenseTypeId);
  }
);

onMounted(() => {
  getLicenseTypes(payload.value);
  getQuestionTypeCountList(payload.value);
});
function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      let data = {
        ...formValue.value,
      };
      let license = data.licenseTypeId;

      data.licenseTypeId = license.id;
      data.licenseTypeNameUz = license.nameUz;
      data.licenseTypeNameRu = license.nameRu;
      data.licenseTypeNameEn = license.nameEn;
      data.licenseTypeNameLt = license.nameLt;
      emits("success", data);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style></style>