<template>
  <n-drawer
    v-model:show="pdfShow"
    :default-width="850"
    resizable
    :placement="placement"
  >
    <n-drawer-content :closable="!noClosable" class="doc-editor">
      <div
        v-if="noClosable"
        class="mb-2 w-full flex items-center justify-between"
      >
        <slot name="buttons"></slot>
        <button @click="pdfShow = false" class="btn btn-light-outline w-8 p-1">
          <XIcon class="w-4" />
        </button>
      </div>
      <embed
        width="100%"
        v-if="base64"
        :src="`data:application/pdf;base64,${base64}`"
        :style="`min-height:100vh; background-color: #ffffff`"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />
    </n-drawer-content>
  </n-drawer>
</template>

<script setup>
import Service from "@/services/interview.service";
import { ref, defineExpose } from "vue";
let props = defineProps({
  placement: {
    default: "left",
  },
  noClosable: {
    default: false,
  },
});
let base64 = ref(null);
let pdfShow = ref(false);
let loading = ref(false);

async function view(body) {
  loading.value = true;
  pdfShow.value = true;
  try {
    base64.value = (await Service.viewPdf(body)).data;
  } finally {
    loading.value = false;
  }
}
function close() {
  pdfShow.value = false;
  loading.value = false;
}
defineExpose({
  view,
  close,
  base64
});
</script>

<style>
</style>