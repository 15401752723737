import ApiService from "./api.service";
import { TokenService } from "./storage.service";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async function(userData) {
    const requestData = {
      method: "post",
      url: "/auth-payload",
      data: {
        username: userData.username,
        password: userData.password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      await TokenService.saveToken(response.data.token);
      await TokenService.setPermissions(response.data.permissions);
      await localStorage.setItem("userId", response.data.id);
      await TokenService.setUserName(response.data.username);
      await TokenService.setUploadPath(response.data.photoUploadPath);
      await TokenService.setFullName(response.data.fullName);
      await TokenService.saveUserRoles(response.data.role);
      await TokenService.setExpanded([]);
      await TokenService.setExpanded2([]);
      await localStorage.setItem("language", "uz");

      ApiService.setHeader();
      ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },
  adminGenerate: async function() {
    await ApiService.get("/generate");
  },

  /** Login by ESP **/
  loginByEsp: async function(userData) {
    const requestData = {
      method: "post",
      url: "/auth-payload",
      data: userData,
    };

    try {
      const response = await ApiService.customRequest(requestData);
      await TokenService.saveToken(response.data.token);
      await localStorage.setItem("userId", response.data.id);
      await TokenService.setUserName(response.data.username);
      await TokenService.setUploadPath(response.data.photoUploadPath);
      await TokenService.setFullName(response.data.fullName);
      await TokenService.saveUserRoles(response.data.role);
      await TokenService.setExpanded([]);
      await TokenService.setExpanded2([]);
      ApiService.setHeader();

      ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeUploadPath();
    TokenService.removeFullName();
    TokenService.removeToken();
    TokenService.removeCurrentUserId();
    TokenService.removeIsAdmin();
    TokenService.removeUserName();
    TokenService.removeUserRoles();

    TokenService.removeSelectedDep();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },
  updatePassword(data) {
    return ApiService.post("/user/update-password", data);
  },

  getKeyForLoginSign(inn, pnfl) {
    return ApiService.get(`/keyForLoginSign?TIN=${inn}&PNFL=${pnfl}`);
  },
};

export { UserService, AuthenticationError };
