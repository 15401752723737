<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <AppBreadcrumb />
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
      <n-dropdown trigger="click" :options="langs" @select="selectDropdown">
        <n-button class="flex items-center">
          <GlobeIcon class="mr-2 w-4" /> {{ $t(`lang.${$i18n.locale}`) }}
        </n-button>
      </n-dropdown>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="
          dropdown-toggle
          w-8
          h-8
          rounded-full
          overflow-hidden
          shadow-lg
          image-fit
          zoom-in
        "
        role="button"
        aria-expanded="false"
        @click="showDrop = true"
      >
        <n-avatar class="text-white bg-theme-1">
          {{ userName ? userName.slice(0, 1).toUpperCase() : "" }}
        </n-avatar>
      </div>
      <div class="dropdown-menu w-52" v-show="showDrop">
        <div
          class="
            dropdown-menu__content
            box
            bg-theme-26
            dark:bg-dark-6
            text-white
          "
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ userName }}</div>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a
              href="javascript:;"
              @click="logout"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </a>
            <a
              href="javascript:;"
              @click="
                router.push({ name: 'change-password' });
                showDrop = false;
              "
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-theme-1
                dark:hover:bg-dark-3
                rounded-md
              "
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" />
              {{ $t("field.changePassword") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import AppBreadcrumb from "@/components/main/app-breadcrumb.vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { t, locale } = useI18n();
    const searchDropdown = ref(false);
    const router = useRouter();
    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };
    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };
    const showDrop = ref(false);
    const store = useStore();
    async function logout() {
      await store.dispatch("auth/logout");
      showDrop.value = false;
    }
    let userName = computed(() => store.state.auth?.userName);
    // lang
    const langs = [
      {
        label: t("lang.lt"),
        key: "lt",
      },
      {
        label: t("lang.uz"),
        key: "uz",
      },
      {
        label: t("lang.ru"),
        key: "ru",
      },
      {
        label: t("lang.eng"),
        key: "eng",
      },
    ];
    function selectDropdown(e) {
      locale.value = e;
      localStorage.setItem("language", e);
      // location.reload();
    }
    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      logout,
      showDrop,
      langs,
      selectDropdown,
      userName,
      router,
    };
  },
});
</script>
