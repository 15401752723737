/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import globalMethods from "./core/globals/index";
import "./libs";
import Loading from "@/components/main/loading.vue";
import NotFound from "@/components/main/not-found.vue";
//
import i18n from "@/core/plugins/vue-i18n.js";
//
import naive from "naive-ui";

// SASS Theme
import "./assets/sass/app.scss";
import "vue-toast-notification/dist/theme-sugar.css";
import ToastPlugin from "vue-toast-notification";

//
import Maska from "maska";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ApiService from "./services/api.service";
import { TokenService } from "./services/storage.service";
import ability from "@/core/globals/ability";
// SET THE BASE_URL OF THE API
ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.mount401Interceptor();

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}
const app = createApp(App)
  .use(Maska)
  .use(CKEditor)
  .use(i18n)
  .use(ToastPlugin)
  .use(naive)
  .use(store)
  .use(router);
app.component("app-loading", Loading);
app.component("not-found", NotFound);
globalComponents(app);
utils(app);
globalMethods(app);
app.config.globalProperties.$baseUrl = process.env.VUE_APP_ROOT_URL;
app.config.globalProperties.$can = ability.can;

app.mount("#app");
