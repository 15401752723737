<template>
  <div>
    <div class="flex items-center justify-between mt-12 mb-5">
      <router-link to="/interview" class="btn btn-primary min-w-24">
        <ArrowLeftIcon class="w-4 mr-1" /> {{ $t("actions.back") }}
      </router-link>
      <h2 class="text-2xl text-theme-3">{{}}</h2>
      <div class="flex items-center">
        <button
          @click="exportExcel"
          class="btn btn-outline-primary min-w-24 inline-block ml-1"
        >
          <DownloadIcon class="w-4 mr-1" /> Excel
        </button>
        <button
          v-if="
            who !== 'OWNER' &&
            sheetData.candidates?.[0].assessors[0].status == 'ATTACHED_ASSESSORS'
          "
          @click="closeShow = true"
          class="btn btn-primary min-w-24 inline-block ml-1"
        >
          <CheckIcon class="w-4 mr-1" /> {{ $t("actions.finish") }}
        </button>
        <button
          @click="finishShow = true"
          v-if="who == 'OWNER' && sheetData.status == 'CLOSED'"
          class="btn btn-primary min-w-24 inline-block ml-1"
        >
          <XIcon class="w-4 mr-1" /> {{ $t("interview.finish") }}
        </button>
      </div>
    </div>
    <SheetAll :sheetData="sheetData" />
  </div>
  <app-loading :show="loading">
    <n-modal
      v-model:show="closeShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('actions.finish')"
      :content="$t('actions.confirm')"
      :negative-text="$t('actions.cancel')"
      :positive-text="$t('actions.save')"
      @positive-click="close"
      @negative-click="closeShow = false"
    />
    <n-modal
      v-model:show="finishShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('interview.finish')"
      :content="$t('actions.confirm')"
      :negative-text="$t('actions.cancel')"
      :positive-text="$t('actions.save')"
      @positive-click="setFinish"
      @negative-click="finishShow = false"
    />
  </app-loading>
</template>

<script setup>
import SheetAll from "./components/SheetAll.vue";
import InterviewService from "@/services/interview.service";
import Util from "@/core/globals/globalMethods";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const message = useMessage();
const { t } = useI18n();
const documentId = ref(Util.dec(route.params.docId));
let sheetData = ref({});
let who = ref("");
async function getSheet() {
  let res = await InterviewService.getSheet(documentId.value);
  sheetData.value = res.data;
  who.value = res.data.who;
}

let closeShow = ref(false);
let finishShow = ref(false);
let loading = ref(false);

async function close() {
  loading.value = true;
  try {
    await InterviewService.close(sheetData.value.candidates[0]?.assessors?.[0]?.id);
    message.success(t("success.saved"));
    router.push("/interview");
  } finally {
    loading.value = false;
  }
}

async function setFinish() {
  try {
    loading.value = true;

    await InterviewService.finished(documentId.value);
    message.success(t("success.saved"));
    router.push("/interview");
  } finally {
    loading.value = false;
  }
}

let showSign = ref(false);
onMounted(() => {
  getSheet();
});

function exportExcel() {
  var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
  var textRange;
  var j = 0;
  let tab = document.getElementById("tbExcel2"); // id of table
  let sa;
  for (j = 0; j < tab.rows.length; j++) {
    tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
  }

  tab_text = tab_text + "</table>";
  tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");
  tab_text = tab_text.replace(/<img[^>]*>/gi, "");
  tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, "");

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    txtArea1.document.open("txt/html", "replace");
    txtArea1.document.write(tab_text);
    txtArea1.document.close();
    txtArea1.focus();
    sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Sumit.xls");
  } else
    sa = window.open("data:application/vnd.ms-excel," + encodeURIComponent(tab_text));
  return sa;
}

function printIt() {
  const prtHtml = document.getElementById("printTarget").innerHTML;
  let stylesHtml = "";
  for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    stylesHtml += node.outerHTML;
  }
  const WinPrint = window.open(
    "",
    "",
    "left=0,top=0,width=2500,height=1000,toolbar=0,scrollbars=0,status=0"
  );
  WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
         
            @page { size: landscape; }
            #printBody {
              background:#fff;
            }
          </style>
            ${stylesHtml}
          </head>
          <body id="printBody">
            ${prtHtml}
          </body>
        </html>`);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.onload = function () {
    WinPrint.print();
    WinPrint.close();
  };
}
</script>
