<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    class="grid grid-cols-2 gap-2"
  >
    <n-form-item
      class="col-span-full"
      :label="$t('questionType')"
      path="typeId"
    >
      <v-select
        v-model="formValue.typeId"
        placeholder=""
        :options="questionTypes"
        filterable
        class="w-full xl:w-1/2"
        :reduce="(el) => el.id"
        :label="$localeKey()"
      >
        <template #option="option">
          <div>
            <div>{{ option[$localeKey()] }}</div>
            <div class="text-xs opacity-90">
              {{ option[$localeKey("licenseTypeName")] }}
            </div>
          </div>
        </template>
      </v-select>
    </n-form-item>
    <n-form-item :label="$t('question.uz')" path="nameUz">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameUz"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('answer.uz')" path="answerNameUz">
      <n-input
        :type="inputType"
        v-model:value="formValue.answerNameUz"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('question.lt')" path="nameLt">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameLt"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('answer.lt')" path="answerNameLt">
      <n-input
        :type="inputType"
        v-model:value="formValue.answerNameLt"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('question.en')" path="nameEn">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameEn"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('answer.en')" path="answerNameEn">
      <n-input
        :type="inputType"
        v-model:value="formValue.answerNameEn"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('question.ru')" path="nameRu">
      <n-input
        :type="inputType"
        v-model:value="formValue.nameRu"
        placeholder=""
      />
    </n-form-item>
    <n-form-item :label="$t('answer.ru')" path="answerNameRu">
      <n-input
        :type="inputType"
        v-model:value="formValue.answerNameRu"
        placeholder=""
      />
    </n-form-item>

    <n-form-item class="flex items-center justify-end mt-4 col-span-full">
      <button @click="$emit('close')" class="btn btn-secondary">
        {{ $t("actions.cancel") }}
      </button>
      <button @click="validate" class="btn btn-primary ml-4">
        {{ $t("actions.save") }}
      </button>
    </n-form-item>
  </n-form>
</template>

<script setup>
import QuestionTypeService from "@/services/reference/question-type.service";

import { ref, watch, onMounted } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
  inputType: {
    default: "textarea",
  },
});
let formValue = ref({
  nameUz: "",
  nameLt: "",
  nameEn: "",
  nameRu: "",
  answerNameUz: "",
  answerNameLt: "",
  answerNameEn: "",
  answerNameRu: "",
  typeId: null,
});
let rules = {
  nameUz: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameLt: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameEn: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  nameRu: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  answerNameUz: {
    // required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  answerNameLt: {
    // required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  answerNameEn: {
    // required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  answerNameRu: {
    // required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  typeId: {
    type: "number",
    // required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};

onMounted(() => {
  if (props.mode == "update") {
    for (const key in formValue.value) {
      if (Object.hasOwnProperty.call(formValue.value, key)) {
        formValue.value[key] = props.item[key];
      }
      formValue.value.id = props.item.id;
    }
  }
});
let payload = ref({
  page: 0,
  limit: 50,
});
let questionTypes = ref([]);
async function getQuestionTypes(data) {
  let res = await QuestionTypeService.getList({ params: data });
  questionTypes.value = res.data.list;
}
onMounted(() => {
  getQuestionTypes(payload.value);
});
function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      emits("success", formValue.value);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style>
</style>