<template>
  <div>
    <div class="intro-y col-span-12 flex justify-end items-center mt-2 mb-4">
      <button
        @click="
          addShow = true;
          mode = 'create';
          currentItem = {};
        "
        class="btn btn-primary shadow-md mr-2"
      >
        {{ $t("actions.add") }}
      </button>
    </div>
    <app-loading :show="loading">
      <div class="overflow-x-scroll lg:overflow-x-auto">
        <div
          class="intro-y "
          style="min-width: 1100px"
        >
          <div
            class="grid grid-cols-11 bg-theme-3 text-white px-2 py-3 rounded-md"
          >
            <div class="col-span-1">#</div>
            <div class="col-span-2 border-r">{{ $t("name.uz") }}</div>
            <div class="col-span-2 border-r text-center">
              {{ $t("name.lt") }}
            </div>
            <div class="col-span-2 border-r text-center">
              {{ $t("name.ru") }}
            </div>
            <div class="col-span-2 border-r text-center">
              {{ $t("name.en") }}
            </div>
            <div class="col-span-2 text-center">{{ $t("actions.title") }}</div>
          </div>
          <Treeview
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            @add="add"
            @delete="
              (e) => {
                currentItem = e;
                deleteShow = true;
              }
            "
            @edit="edit"
          >
          </Treeview>
        </div>
      </div>
    </app-loading>
    <!-- EDIT -->
    <n-modal v-model:show="addShow" :auto-focus="false">
      <n-card
        style="width: 600px"
        title="Qo'shish"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <AddUpdate
          ref="addRef"
          @success="send"
          @close="addShow = false"
          :item="currentItem"
          :mode="mode"
          input-type="input"
        />
      </n-card>
    </n-modal>
    <!-- DELETE -->
    <n-modal
      v-model:show="deleteShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('actions.delete')"
      :content="$t('sureDelete')"
      :positive-text="$t('actions.delete')"
      :negative-text="$t('actions.cancel')"
      @positive-click="deleteItem"
      @negative-click="deleteShow = false"
    />
  </div>
</template>

<script setup>
import Treeview from "./Treview.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";

import MainService from "@/services/reference/region.service";
import AddUpdate from "../components/AddUpdate.vue";
const { t } = useI18n();
const loadingBar = useLoadingBar();
const message = useMessage();

const addRef = ref(null);
let loading = ref(false);
let list = ref([]);
let addShow = ref(false);
let deleteShow = ref(false);
async function getList() {
  try {
    loading.value = true;
    let res = await MainService.getList();
    list.value = res.data;
  } finally {
    loading.value = false;
  }
}
async function deleteItem() {
  try {
    loadingBar.start();
    await MainService.delete(currentItem.value.id);
    getList();
  } finally {
    loadingBar.finish();
  }
}
function edit(e) {
  currentItem.value = e;
  mode.value = "update";
  addShow.value = true;
}
function add(e) {
  currentItem.value = e;
  mode.value = "create";
  addShow.value = true;
}
async function send(e) {
  let payload = {
    nameUz: e.nameUz,
    nameEn: e.nameEn,
    nameRu: e.nameRu,
    nameLt: e.nameLt,
    parentId: currentItem.value.id || "",
  };
  try {
    loadingBar.start();
    if (mode.value == "update") {
      await MainService.update({
        nameUz: e.nameUz,
        nameEn: e.nameEn,
        nameRu: e.nameRu,
        nameLt: e.nameLt,
        id: currentItem.value.id,
      });
    } else {
      await MainService.create({
        nameUz: e.nameUz,
        nameEn: e.nameEn,
        nameRu: e.nameRu,
        nameLt: e.nameLt,
        parentId: currentItem.value.id || "",
      });
    }
    addShow.value = false;
    getList();
  } finally {
    loadingBar.finish();
  }
}
onMounted(() => {
  getList();
});
let currentItem = ref({});
let mode = ref("create");
</script>

<style>
</style>