import ApiService from "../api.service";
const main = "permission";

export default {
  getList() {
    return ApiService.get(`${main}/list`);
  },
  listByRoleId(roleId) {
    return ApiService.get(`${main}/list-by-roleId/${roleId}`);
  },
  addPermission({ roleId, permissionIds }) {
    return ApiService.post(`role/add-permission/${roleId}`, permissionIds);
  },
};
