<template>
  <div>
    <div
      class="
        intro-y
        col-span-12
        flex flex-wrap
        sm:flex-nowrap
        items-center
        mt-2
      "
    >
      <div
        class="
          w-full
          sm:w-auto 
          mt-3
          sm:mt-0 sm:ml-auto
          md:ml-0
          flex
          items-center
          mb-4
        "
      >
        <div class="w-56 relative text-gray-700 dark:text-gray-300">
          <input
            type="text"
            class="form-control w-56 box pr-10 placeholder-theme-13"
            :placeholder="$t('search')"
            v-model="search"
          />
          <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
        </div>
        <select v-model="limit" class="w-20 form-select box ml-4">
          <option v-for="item in limitOpt" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
      <div class="hidden md:block mx-auto text-gray-600">
        {{ total }} {{ $t("dan") }} {{ list.length }} {{ $t("showingOf") }}
      </div>
      <button
        @click="
          addShow = true;
          mode = 'create';
        "
        class="btn btn-primary shadow-md mr-2"
      >
        {{ $t("actions.add") }}
      </button>
    </div>
    <app-loading :show="loading">
      <n-table striped>
        <thead>
          <tr class="text-center">
            <th>#</th>
            <th>{{ $t("name.uz") }}</th>
            <th>{{ $t("name.lt") }}</th>
            <th>{{ $t("name.en") }}</th>
            <th>{{ $t("name.ru") }}</th>
            <th>{{ $t("questionTypeCount") }}</th>
            <th>{{ $t("lisenceType") }}</th>
            <th>{{ $t("actions.title") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length">
          <tr class="text-center" v-for="(item, i) in list" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.nameUz }}</td>
            <td>{{ item.nameLt }}</td>
            <td>{{ item.nameEn }}</td>
            <td>{{ item.nameRu }}</td>
            <td>{{ item[$localeKey('typeCountName')] }}</td>
            <td>{{ item[$localeKey('licenseTypeName')] }}</td>
            <td>
              <button
                @click="
                  currentItem = item;
                  deleteShow = true;
                "
                class="btn btn-danger w-8 h-8 p-0 rounded-full mr-2"
              >
                <TrashIcon class="w-4" />
              </button>
              <button
                class="btn btn-primary w-8 h-8 p-0 rounded-full"
                @click="showEdit(item)"
              >
                <EditIcon class="w-4" />
              </button>
            </td>
          </tr>
        </tbody>
        <not-found v-else colspan="6" />
      </n-table>
      <n-pagination
        class="my-4"
        v-model:page="page"
        :page-count="Math.ceil(total / limit)"
      />
    </app-loading>
    <!-- EDIT -->
    <n-modal v-model:show="addShow" :auto-focus="false">
      <n-card
        style="width: 450px"
        title="Qo'shish"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <AddUpdate
          ref="addRef"
          @success="send"
          @close="addShow = false"
          :item="currentItem"
          :mode="mode"
          inputType="text"
        />
      </n-card>
    </n-modal>
    <!-- DELETE -->
    <n-modal
      v-model:show="deleteShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('actions.delete')"
      :content="$t('sureDelete')"
      :positive-text="$t('actions.delete')"
      :negative-text="$t('actions.cancel')"
      @positive-click="deleteItem"
      @negative-click="deleteShow = false"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";

import MainService from "@/services/reference/question-type.service";
import AddUpdate from "../components/AddUpdateQType.vue";
const { t } = useI18n();
const loadingBar = useLoadingBar();
const message = useMessage();

const addRef = ref(null);
let loading = ref(false);
let limitOpt = [10, 20, 50, 100];
let limit = ref(20);
let search = ref("");
let page = ref(1);
let total = ref(0);
let list = ref([]);
let addShow = ref(false);
let deleteShow = ref(false);
let payload = computed(() => {
  return {
    search: search.value,
    params: {
      limit: limit.value,
      page: page.value - 1,
    },
  };
});
watch(() => payload.value, getList, { deep: true });
async function getList() {
  try {
    loading.value = true;
    let res = await MainService.getList(payload.value);
    list.value = res.data.list;
    total.value = res.data.total;
  } finally {
    loading.value = false;
  }
}
async function send(e) {
  let action = mode.value == "update" ? "update" : "create";
  loadingBar.start();
  try {
    await MainService[action](e);
    addShow.value = false;
    message.success(t("success.saved"));
    getList();
  } finally {
    loadingBar.finish();
  }
}
async function deleteItem() {
  try {
    loadingBar.start();
    await MainService.delete(currentItem.value.id);
    getList();
  } finally {
    loadingBar.finish();
  }
}
onMounted(() => {
  getList();
});
let currentItem = ref({});
let mode = ref("create");
function showEdit(item) {
  currentItem.value = item;
  mode.value = "update";
  addShow.value = true;
}
</script>

<style>
</style>