const TOKEN_KEY = "access_token";
const USER_ID_KEY = "userId";
const IS_ADMIN = "isAdmin";
const USERNAME = "userName";
const ROLE = "role";
const SELECTED_DEP = "selected_department";
const EXPANDED = "expanded";
const FULLNAME = "fullName";
const PATH = "path";
const EXPANDEDD = "expanded_order_type";
const PERMISSIONS = "store";

const TokenService = {
  setPermissions(data) {
    return localStorage.setItem(PERMISSIONS, JSON.stringify(data));
  },
  removePermissions() {
    return localStorage.removeItem(PERMISSIONS);
  },
  getPermissions() {
    return JSON.parse(localStorage.getItem(PERMISSIONS));
  },
  setUploadPath(data) {
    return localStorage.setItem(PATH, data);
  },
  removeUploadPath() {
    return localStorage.removeItem(PATH);
  },
  getUploadPath() {
    return localStorage.getItem(PATH);
  },
  setFullName(data) {
    return localStorage.setItem(FULLNAME, data);
  },
  removeFullName() {
    return localStorage.removeItem(FULLNAME);
  },
  getFullName() {
    return localStorage.getItem(FULLNAME);
  },

  setExpanded2(data) {
    return localStorage.setItem(EXPANDEDD, JSON.stringify(data));
  },
  removeExpanded2() {
    return localStorage.removeItem(EXPANDEDD);
  },
  getExpanded2() {
    return JSON.parse(localStorage.getItem(EXPANDEDD));
  },

  setExpanded(data) {
    return localStorage.setItem(EXPANDED, JSON.stringify(data));
  },
  removeExpanded() {
    return localStorage.removeItem(EXPANDED);
  },
  getExpanded() {
    return JSON.parse(localStorage.getItem(EXPANDED));
  },
  setSelectedDep(data) {
    return localStorage.setItem(SELECTED_DEP, JSON.stringify(data));
  },
  getSelectedDep() {
    return JSON.parse(localStorage.getItem(SELECTED_DEP));
  },
  removeSelectedDep() {
    return localStorage.removeItem(SELECTED_DEP);
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  saveToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },
  saveCurrentUserId(id) {
    localStorage.setItem(USER_ID_KEY, id);
  },
  getCurrentUserId() {
    return localStorage.getItem(USER_ID_KEY);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  removeCurrentUserId() {
    localStorage.removeItem(USER_ID_KEY);
  },
  setIsAdmin(boolean) {
    localStorage.setItem(IS_ADMIN, boolean);
  },
  getIsAdmin() {
    return localStorage.getItem(IS_ADMIN);
  },
  removeIsAdmin() {
    localStorage.removeItem(IS_ADMIN);
  },
  saveUserRoles(role) {
    localStorage.setItem(ROLE, JSON.stringify(role));
  },
  getUserRoles() {
    return JSON.parse(localStorage.getItem(ROLE));
  },
  removeUserRoles() {
    localStorage.removeItem(ROLE);
  },
  setUserName(userName) {
    localStorage.setItem(USERNAME, userName);
  },
  getUserName() {
    return localStorage.getItem(USERNAME);
  },
  removeUserName() {
    return localStorage.removeItem(USERNAME);
  },
};

export { TokenService };
