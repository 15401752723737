<template>
  <div>
    <div
      class="
        intro-y
        col-span-12
        flex flex-wrap
        sm:flex-nowrap
        items-center
        mt-2
      "
    >
      <div
        class="
          w-full
          sm:w-auto
          mt-3
          sm:mt-0 sm:ml-auto
          md:ml-0
          mb-4
          grid grid-cols-12
          -mx-1
        "
      >
        <div class="col-span-3 mx-2">
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-full box pr-10 placeholder-theme-13"
              :placeholder="$t('search')"
              v-model="search"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
        <div class="col-span-3 mx-2">
          <CSelectNaive
            v-model:value="questionTypeId"
            :options="typeOpt"
            :label="$localeKey('name')"
            :placeholder="$t('questionType')"
            class="w-full ml-4"
          />
        </div>
        <div class="col-span-3 mx-2">
          <CSelectNaive
            v-model:value="licenseTypeId"
            :options="licenseList"
            :label="$localeKey('name')"
            :placeholder="$t('lisenceType')"
            class="w-full ml-4"
          />
        </div>
        <div class="col-span-2 flex justify-items-end mx-2">
          <select v-model="limit" class="w-20 form-select box ml-4">
            <option v-for="item in limitOpt" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-span-1 mx-2">
          <button
            @click="
              addShow = true;
              mode = 'create';
            "
            class="btn btn-primary shadow-md mr-2"
          >
            {{ $t("actions.add") }}
          </button>
        </div>
      </div>
      <!-- <div class="hidden md:block mx-auto text-gray-600">
        {{ total }} {{ $t("dan") }} {{ list.length }} {{ $t("showingOf") }}
      </div> -->
    </div>
    <app-loading :show="loading" class="overflow-x-auto xl:overflow-x-scroll">
      <n-table striped>
        <thead>
          <tr class="text-center">
            <th>#</th>
            <th>{{ $t("question.uz") }}</th>
            <th>{{ $t("question.lt") }}</th>
            <th>{{ $t("question.en") }}</th>
            <th>{{ $t("question.ru") }}</th>
            <th>{{ $t("questionType") }}</th>
            <th>{{ $t("actions.title") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length">
          <tr class="text-center" v-for="(item, i) in list" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.nameUz }}</td>
            <td>{{ item.nameLt }}</td>
            <td>{{ item.nameEn }}</td>
            <td>{{ item.nameRu }}</td>
            <td>{{ item[$localeKey("typeName")] }}</td>
            <td>
              <button
                @click="
                  currentItem = item;
                  deleteShow = true;
                "
                class="btn btn-danger w-8 h-8 p-0 rounded-full mr-2"
              >
                <TrashIcon class="w-4" />
              </button>
              <button
                class="btn btn-primary w-8 h-8 p-0 rounded-full mr-2"
                @click="showEdit(item)"
              >
                <EditIcon class="w-4" />
              </button>
              <input
                class="form-check-switch"
                type="checkbox"
                v-model="item.active"
                id="archiveInterview"
                @change="changeActive(item)"
              />
            </td>
          </tr>
        </tbody>
        <not-found v-else colspan="6" />
      </n-table>
      <n-pagination
        class="my-4"
        v-model:page="page"
        :page-count="Math.ceil(total / limit)"
      />
    </app-loading>
    <!-- EDIT -->
    <n-modal v-model:show="addShow" :auto-focus="false">
      <n-card
        style="width: 900px"
        title="Qo'shish"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <AddUpdate
          ref="addRef"
          @success="send"
          @close="addShow = false"
          :item="currentItem"
          :mode="mode"
        />
      </n-card>
    </n-modal>
    <!-- DELETE -->
    <n-modal
      v-model:show="deleteShow"
      :mask-closable="false"
      preset="dialog"
      :title="$t('actions.delete')"
      :content="$t('sureDelete')"
      :positive-text="$t('actions.delete')"
      :negative-text="$t('actions.cancel')"
      @positive-click="deleteItem"
      @negative-click="deleteShow = false"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";
import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";

import MainService from "@/services/reference/question.service";
import QuestionTypes from "@/services/reference/question-type.service";
import AddUpdate from "../components/AddUpdateQuestion.vue";
const { t } = useI18n();
const loadingBar = useLoadingBar();
const message = useMessage();

const addRef = ref(null);
let loading = ref(false);
let limitOpt = [10, 20, 50, 100];
let limit = ref(20);
let search = ref("");
let page = ref(1);
let total = ref(0);
let list = ref([]);
let questionTypeId = ref("");
let typeOpt = ref([]);
let licenseTypeId = ref("");
let licenseList = ref([]);
let addShow = ref(false);
let deleteShow = ref(false);

let changeActive = async (item) => {
  try {
    loading.value = true;
    let data = {
      id: item.id,
      active: item.active,
    };
    let res = await MainService.change(data);
    message.success(res.message);
  } finally {
    getList();
    loading.value = false;
  }
};

let payload = computed(() => {
  return {
    search: search.value,
    questionTypeId: questionTypeId?.value?.id || "",
    licenseTypeId: licenseTypeId?.value?.id || "",
    params: {
      limit: limit.value,
      page: page.value - 1,
    },
  };
});
watch(() => payload.value, getList, { deep: true });
async function getList() {
  try {
    loading.value = true;
    let res = await MainService.getList(payload.value);
    list.value = res.data.list;
    total.value = res.data.total;
  } finally {
    loading.value = false;
  }
}

async function getQuestionTypes() {
  try {
    let res = await QuestionTypes.getList({
      params: {
        limit: 100,
        page: 0,
      },
    });
    typeOpt.value = res.data.list;
  } finally {
  }
}

async function getlicenseType() {
  try {
    let res = await MainService.licenseTypes({
      params: {
        limit: 100,
        page: 0,
      },
    });
    licenseList.value = res.data.list;
  } finally {
  }
}

async function send(e) {
  let action = mode.value == "update" ? "update" : "create";
  loadingBar.start();
  try {
    await MainService[action](e);
    addShow.value = false;
    message.success(t("success.saved"));
    getList();
  } finally {
    loadingBar.finish();
  }
}
async function deleteItem() {
  try {
    loadingBar.start();
    await MainService.delete(currentItem.value.id);
    getList();
  } finally {
    loadingBar.finish();
  }
}
onMounted(() => {
  getList();
  getQuestionTypes();
  getlicenseType();
});
let currentItem = ref({});
let mode = ref("create");
function showEdit(item) {
  currentItem.value = item;
  mode.value = "update";
  addShow.value = true;
}
</script>

<style>
</style>