import { createRouter, createWebHistory } from "vue-router";
import { h, resolveComponent } from "vue";
import { TokenService } from "@/services/storage.service";
import { useStore } from "@/store";

import SideMenu from "../layouts/side-menu/Main.vue";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/login/Main.vue";
import ChangePassword from "../views/change-password/Main.vue";
import Register from "../views/register/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";

import FailedList from "../views/failed-list/List.vue";
import Interviews from "../views/interview/List.vue";
import InterviewAddCom from "@/views/interview/AddCommission.vue";
import InterviewSheet from "@/views/interview/Sheet.vue";
// REFERENCES
import References from "@/views/reference/Index.vue";
import LicenseType from "@/views/reference/license-type/List.vue";
import QuestionTypeCount from "@/views/reference/question-type-count/List.vue";
import QuestionType from "@/views/reference/question-type/List.vue";
import Question from "@/views/reference/question/List.vue";
import Permission from "@/views/reference/permissions/List.vue";
import Document from "@/views/reference/document/List.vue";
import CommissionType from "@/views/reference/commission-type/List.vue";
import Region from "@/views/reference/region/List.vue";
import Contragent from "@/views/reference/contragent/List.vue";
import StaffPosition from "@/views/reference/staff-position/List.vue";
import Role from "@/views/reference/role/List.vue";
// USERS

import UsersComp from "@/views/users/List.vue";
import Comissions from "@/views/comissions/List.vue";

const store = useStore();

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "failed-list",
        name: "FailedList",
        component: FailedList,
      },
      {
        path: "/interview",
        name: "Interview",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          title: "menu.interview",
        },
        children: [
          {
            path: "",
            name: "Interview-view",
            component: Interviews,
          },
          {
            path: ":docId/add-commission",
            name: "InterviewAddCom",
            component: InterviewAddCom,
            // beforeEnter: (to, from, next) => {
            //   if (from.name == 'Interview-view') {
            //     return next()
            //   }
            //   else {
            //     return next('/interview')
            //   }
            // },
            meta: {
              title: "interview.addCommission",
            },
          },
          {
            path: ":docId/sheet",
            name: "InterviewSheet",
            component: InterviewSheet,
            // beforeEnter: (to, from, next) => {
            //   if (from.name == 'Interview-view' || from.name == 'InterviewAddCom') {
            //     return next()
            //   }
            //   else {
            //     return next('/interview')
            //   }
            // },
            meta: {
              title: "interview.sheet",
            },
          },
        ],
      },

      {
        path: "/user",
        name: "User",
        component: UsersComp,
        meta: {
          title: "menu.users",
        },
      },

      {
        path: "/comissions",
        name: "Comissions",
        component: Comissions,
        meta: {
          title: "menu.comissions",
        },
      },
      // REFERENCE
      {
        path: "reference",
        component: References,
        name: "References",
        meta: {
          title: "menu.reference",
        },
        children: [
          {
            path: "document",
            component: Document,
            name: "Document",
          },
          {
            path: "commission-type",
            component: CommissionType,
            name: "CommissionType",
          },
          {
            path: "region",
            component: Region,
            name: "Region",
          },
          {
            path: "contragent",
            component: Contragent,
            name: "Contragent",
          },
          {
            path: "role",
            component: Role,
            name: "Role",
          },
          {
            path: "staff-position",
            component: StaffPosition,
            name: "StaffPosition",
          },
          {
            path: "lisence-type",
            component: LicenseType,
            name: "LicenseType",
          },
          {
            path: "question-type",
            component: QuestionType,
            name: "QuestionType",
          },
          {
            path: "question-type-count",
            component: QuestionTypeCount,
            name: "QuestionTypeCount",
          },

          {
            path: "question",
            component: Question,
            name: "Question",
          },

          {
            path: "permission",
            component: Permission,
            name: "Permission",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },

  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!TokenService.getToken();
  const role = TokenService.getUserRoles();
  store.dispatch("permission/getPermissions");
  // if (from.name == "login" && to.name == "Dashboard") {
  // }
  if (
    from.name == "login" &&
    to.name == "Dashboard" &&
    role?.code == "ROLE_USER"
  ) {
    return next("/interview");
  }
  if (from.name == "login" && to.name == "Dashboard" && role?.code == "Admin") {
    return next("/user");
  }
  if (!loggedIn && to.name !== "login") {
    return next("/login");
  }
  if (loggedIn && to.name == "login") {
    return next({ name: from.name });
  }
  return next();
});

export default router;
