<template>
  <div>
    <div class="intro-y mt-2">
      <div
        class="
          w-full
          sm:w-auto
          mt-3
          sm:mt-0 sm:ml-auto
          md:ml-0
          items-center
          grid grid-cols-12
          -mx-2
          mb-4
        "
      >
        <div class="col-span-3 mx-2 relative text-gray-700 dark:text-gray-300">
          <input
            type="text"
            class="form-control w-full box pr-10 placeholder-theme-13"
            placeholder="Qidiruv"
            v-model="search"
          />
          <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
        </div>
        <div class="col-span-5 mx-2">
          <c-select-naive
            v-if="roles.includes(role?.code)"
            class="w-full"
            :label="$localeKey('name')"
            :options="contragents"
            v-model:value="contragentId"
          />
        </div>
        <div class="col-span-2 mx-2">
          <select v-model="limit" class="form-select">
            <option v-for="item in limitOpt" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
        <!-- <div class="hidden md:block mx-auto text-gray-600">
          {{ total }} dan {{ list.length }} ta ko'rsatilmoqda
        </div> -->
        <div class="col-span-2 mx-2 text-right">
          <button
            @click="
              addShow = true;
              mode = 'create';
            "
            class="btn btn-primary shadow-md"
          >
            {{ $t("actions.add") }}
          </button>
        </div>
      </div>
    </div>
    <app-loading :show="loading">
      <div class="intro-y overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr class="text-center">
              <th class="w-5">#</th>
              <th class="">{{ $t("field.fio") }}</th>
              <th>{{ $t("field.comType") }}</th>
              <th>{{ $t("actions.title") }}</th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr class="text-center" v-for="(item, i) in list" :key="i">
              <td class="w-5">{{ i + 1 }}</td>
              <td>
                <div>
                  {{ item.lastName }} {{ item.firstName }} {{ item.parentName }}
                </div>
                <div class="text-sm text-theme-3">
                  {{ item[$localeKey("contragentName")] }}
                </div>
              </td>
              <td>{{ item[$localeKey("interviewAppraisersName")] }}</td>
              <td>
                <button
                  @click="
                    currentItem = item;
                    deleteShow = true;
                  "
                  class="btn btn-danger w-8 h-8 p-0 rounded-full mr-2"
                >
                  <TrashIcon class="w-4" />
                </button>
                <button
                  class="btn btn-primary w-8 h-8 p-0 rounded-full"
                  @click="showEdit(item)"
                >
                  <EditIcon class="w-4" />
                </button>
                <!-- <input
                  class="form-check-switch ml-2"
                  type="checkbox"
                  v-model="item.active"
                  id="archiveInterview"
                  @change="changeActive(item)"
                /> -->
              </td>
            </tr>
          </tbody>
          <not-found colspan="5" v-else />
        </table>
        <n-pagination
          class="mt-4"
          v-model:page="page"
          :page-count="Math.ceil(total / limit)"
        />
      </div>
    </app-loading>
    <!-- EDIT -->
    <n-modal v-model:show="addShow" :auto-focus="false">
      <n-card
        style="width: 600px"
        title="Qo'shish"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <AddUpdate
          ref="addRef"
          @success="send"
          @close="addShow = false"
          :item="currentItem"
          :mode="mode"
          input-type="input"
        />
      </n-card>
    </n-modal>
    <!-- DELETE -->
    <n-modal
      v-model:show="deleteShow"
      :mask-closable="false"
      preset="dialog"
      title="O'chirish"
      content="Rostan ham o'chirmoqchimisiz?"
      positive-text="O'chirish"
      negative-text="Bekor qilish"
      @positive-click="deleteItem"
      @negative-click="deleteShow = false"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLoadingBar, useMessage } from "naive-ui";
import MainService from "@/services/reference/comission.service";
import ContragentService from "@/services/reference/contragent.service";
import { TokenService } from "@/services/storage.service";

import AddUpdate from "../components/ComissionForm.vue";
import CSelectNaive from "@/components/hrm-components/CSelectNaive.vue";
const { t } = useI18n();
const loadingBar = useLoadingBar();
const message = useMessage();

const addRef = ref(null);
let loading = ref(false);
let limitOpt = [10, 20, 50, 100];
let limit = ref(20);
let search = ref("");
let deleteShow = ref(false);
let page = ref(1);
let total = ref(0);
let list = ref([]);
let contragents = ref([]);
let role = ref("");
let roles = ref(["ROLE_ADMIN", "ROLE_MAIN_ADMIN"]);
let contragentId = ref("");
let addShow = ref(false);
let pyloadComp = computed(() => {
  return {
    contragentId: contragentId?.value?.id || "",
    params: {
      limit: limit.value,
      page: page.value - 1,
    },
  };
});
watch(pyloadComp, getList, { deep: true });
async function getList() {
  try {
    loading.value = true;
    let res = await MainService.getList(pyloadComp.value);
    list.value = res.data.list;
    total.value = res.data.total;
  } finally {
    loading.value = false;
  }
}

async function geContragents() {
  let res = await ContragentService.getList({
    params: {
      limit: 100,
      page: 0,
    },
  });
  contragents.value = res.data.list;
}

let changeActive = async (item) => {
  try {
    loading.value = true;
    let data = {
      id: item.id,
      active: item.active,
    };
    let res = await MainService.change(data);
    // message.success(res.message);
  } finally {
    getList();
    loading.value = false;
  }
};

async function send(e) {
  let action = mode.value == "update" ? "update" : "create";
  loadingBar.start();
  e.membersId = e.id;
  try {
    await MainService[action](e);
    addShow.value = false;
    message.success(t("success.saved"));
    getList();
  } finally {
    loadingBar.finish();
  }
}
async function deleteItem() {
  try {
    loadingBar.start();
    await MainService.delete(currentItem.value.id);
    getList();
  } finally {
    loadingBar.finish();
  }
}
onMounted(() => {
  getList();
  geContragents();
  role.value = TokenService.getUserRoles();
});
let currentItem = ref({});
let mode = ref("create");
function showEdit(item) {
  currentItem.value = item;
  mode.value = "update";
  addShow.value = true;
}
</script>

<style>
</style>