<template>
  <n-form
    ref="formRef"
    :label-width="80"
    :model="formValue"
    :rules="rules"
    :size="size"
    style="max-width: 700px"
  >
    <div class="grid grid-cols-2 gap-x-4">
      <n-form-item :label="$t('field.firstName')" path="firstName">
        <n-input v-model:value="formValue.firstName" placeholder="" />
      </n-form-item>
      <n-form-item :label="$t('field.lastName')" path="lastName">
        <n-input v-model:value="formValue.lastName" placeholder="" />
      </n-form-item>
      <n-form-item :label="$t('field.parentName')" path="parentName">
        <n-input v-model:value="formValue.parentName" placeholder="" />
      </n-form-item>
      <n-form-item
        v-maska="'+9989 9# ### ## ##'"
        :label="$t('field.phone')"
        path="phone"
      >
        <n-input v-model:value="formValue.phone" placeholder="" />
      </n-form-item>
      <n-form-item :label="$t('field.pinfl')" path="pnfl">
        <n-input
          v-maska="'##############'"
          v-model:value="formValue.pnfl"
          placeholder=""
        />
      </n-form-item>
      <!-- <n-form-item :label="$t('field.inn')" path="inn">
        <n-input
          v-maska="'#########'"
          v-model:value="formValue.inn"
          placeholder=""
        />
      </n-form-item> -->
      <n-form-item :label="$t('field.contragent')" path="contragentId">
        <v-select
          v-model="formValue.contragentId"
          placeholder=""
          :options="contragents"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          :label="$localeKey()"
        />
      </n-form-item>
      <n-form-item :label="$t('field.staffPosition')" path="positionId">
        <v-select
          v-model="formValue.positionId"
          placeholder=""
          :options="positions"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          :label="$localeKey()"
        />
      </n-form-item>
      <n-form-item :label="$t('field.role')" path="roleId">
        <v-select
          v-model="formValue.roleId"
          placeholder=""
          :options="roles"
          filterable
          class="w-full"
          :reduce="(el) => el.id"
          label="name"
        />
      </n-form-item>

      <n-form-item :label="$t('field.login')" path="username-create">
        <n-input v-model:value="formValue.username" placeholder="" />
      </n-form-item>
      <!-- v-if="mode == 'create'" -->
      <n-form-item :label="$t('field.password')" path="password">
        <n-input
          type="password"
          v-model:value="formValue.password"
          placeholder=""
          show-password-on="click"
        >
        </n-input>
      </n-form-item>
      <!-- v-if="mode == 'create'" -->
      <n-form-item :label="$t('field.passwordConfirm')" path="passwordConfirm">
        <n-input
          type="password"
          v-model:value="formValue.passwordConfirm"
          placeholder=""
          show-password-on="click"
        >
        </n-input>
      </n-form-item>

      <n-form-item class="flex items-center justify-end mt-4 col-span-full">
        <button @click="$emit('close')" class="btn btn-secondary">
          {{ $t("actions.cancel") }}
        </button>
        <button @click="validate" class="btn btn-primary ml-4">
          {{ $t("actions.save") }}
        </button>
      </n-form-item>
    </div>
  </n-form>
</template>

<script setup>
import ContragentService from "@/services/reference/contragent.service";
import RoleService from "@/services/reference/role.service";
import StaffService from "@/services/reference/staff-position.service";

import { ref, watch, onMounted, computed } from "vue";
import { useMessage } from "naive-ui";
import { useI18n } from "vue-i18n";
const formRef = ref(null);
const message = useMessage();
const { t } = useI18n();
let size = ref("medium");
const emits = defineEmits(["close", "success"]);
const props = defineProps({
  item: {
    default: () => {},
  },
  mode: {
    default: "create",
  },
});
let formValue = ref({
  firstName: "",
  lastName: "",
  parentName: "",
  username: "",
  pnfl: "",
  password: "",
  passwordConfirm: "",
  contragentId: "",
  positionId: "",
  // inn: "",
  roleId: "",
});
let rules = {
  firstName: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  lastName: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  parentName: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  username: {
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  password: {
    required: props.mode == "create",
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  passwordConfirm: {
    validator(rule, value) {
      return value === formValue.value.password;
    },
    message: t("error.field"),
    trigger: ["input", "blur"],
  },
  contragentId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  positionId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
  pnfl: {
    validator(rule, value) {
      return value?.length == 14;
    },
    message: t("error.field"),
    trigger: ["input", "blur"],
  },
  // inn: {
  //   validator(rule, value) {
  //     return value?.length == 9;
  //   },
  //   message: t("error.field"),
  //   trigger: ["input", "blur"],
  // },
  phone: {
    validator(rule, value) {
      return value?.length == 18;
    },
    message: t("error.field"),
    trigger: ["input", "blur"],
  },
  roleId: {
    type: "number",
    required: true,
    message: t("error.empty"),
    trigger: ["input", "blur"],
  },
};
// Options
let payload = ref({
  page: 0,
  limit: 50,
});
let contragents = ref([]);
let positions = ref([]);
let roles = ref([]);
async function getContragent(data) {
  let res = await ContragentService.getList({ params: data });
  contragents.value = res.data.list;
}
async function getPosition(data) {
  let res = await StaffService.getList({ params: data });
  positions.value = res.data.list;
}
async function getRole(data) {
  let res = await RoleService.getList({ params: data });
  roles.value = res.data.list;
}
onMounted(() => {
  if (props.mode == "update") {
    formValue.value = { ...props.item };
    formValue.value.password = "";
    formValue.value.passwordConfirm = "";
  }
  getContragent(payload.value);
  getPosition(payload.value);
  getRole(payload.value);
});

function validate(e) {
  e.preventDefault();
  formRef.value?.validate((errors) => {
    if (!errors) {
      if (props.mode == "update" && !formValue.value.password) {
        delete formValue.value.password;
        delete formValue.value.passwordConfirm;
      }
      emits("success", formValue.value);
    } else {
      message.error(t("error.invalid"));
    }
  });
}
</script>

<style>
</style>